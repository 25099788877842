
export default {
    name: 'PROGRAM service repairs',
    tab_name: 'Parts Serviced / Repaired',
    type: 'table',
    table: 'service_repair',
    lines: [
        [
            {name: 'Service No.', id: 'service_repair.service_id', type: 'select', source: 'service.chassis_no', value:{value:'service_id', name:'chassis_no'}, span: 1},
            {name: 'Vehicle Id', id: 'service.chassis_no', type: 'reference', reference: 'service_repair.service_id', span: 1, disabled:false},
        ], [
            {name: 'Part Repaired', id: 'service_repair.part', type: 'select', source:'service_repair', options:{value:'part'}, creatable: true, span: 1},
            {name: 'Cost', id: 'service_repair.cost', type: 'number', span: 1},
        ], [
            {name: 'Remarks', id: 'vehicle_service.remarks', type: 'textarea', span: 3}
        ]
    ],

}


