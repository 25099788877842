import React from 'react'

export default ({title, size = 12, color='primary', ...rest}) => {
    return (
        <div className={`col-md-${size}`}>
            <div className={`box box-${color}`}>
                <div className="box-header with-border">
                    <h3 className="box-title">{title}</h3>

                    <div className="box-tools pull-right">
                        <button type="button" className="btn btn-box-tool" data-widget="collapse"><i className="fa fa-minus"/>
                        </button>
                        <button type="button" className="btn btn-box-tool" data-widget="remove"><i className="fa fa-times"/></button>
                    </div>
                </div>
                <div className="box-body">
                        {/*<canvas id="areaChart" style={{height:' 250px', width: '789px'}} height="250" width="789"/>*/}
                        {rest.children}
                </div>
            </div>
        </div>
    )
}