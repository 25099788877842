import React from 'react'
import {connect} from 'react-redux'
import {withRouter, BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import Dashboard from './page_dashboard'
import RoutePage from './page_route'
import Login from './login'
import Report from './page_report'
import Formiks from './page_form'
import configs from "../custom/configs";
import {HotApp} from "../custom/forms/handson";
import Style from "./StyledBody";
import ReactDOM from 'react-dom'

const mapStateToProps = (state, ownProps) => {
        return {
            ...ownProps,
            isAuthenticated: state.authReducer.isAuthenticated
        }
    },
    LoginModal = () => {
        return ReactDOM.createPortal(
            <div className={`modal fade in`} id="fa_modal_window" tabIndex="100" role="dialog" aria-labelledby="fa_modal_label" style={{display: 'block', backgroundColor: 'white',zIndex:100000}}/*onClick={closeModal}*/>
                <Login redirect={false}/>
            </div>,
            document.body
        )
    }

export const PrivateRoute = connect(mapStateToProps)(({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={(props) => (
            (!rest.isAuthenticated && configs.redirect_to_login)
                ? <Redirect to='/'/> :
                <>
                    {!rest.isAuthenticated && <LoginModal/>}
                    <Component key={rest.location.pathname} {...props} />
                </>
        )}/>
    )
});

/*
const Empty = (props) => {
    console.log(props)
    // props.history.goBack()
    return (
        <div/>
    )
}
*/
const mainBody = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={withRouter(Login)}/>
                <PrivateRoute path='/dashboard' component={withRouter(configs.dashboard ? Style(configs.dashboard) : Dashboard)}/>
                <PrivateRoute exact path="/page/:target" component={withRouter(RoutePage)}/>
                <PrivateRoute exact path="/page/:target/:target2" component={withRouter(RoutePage)}/>
                {/*<Route exact path="/page/:target" component={withRouter(RoutePage)}/>*/}
                {/*<Route exact path="/page/:target/:target2" render={(props) => {*/}
                {/*    let Component = withRouter(RoutePage)*/}
                {/*    console.log('TARGET WITH 2 PARAMETERS LOADED')*/}
                {/*    return <Component {...props}/>*/}
                {/*}}*/}
                {/*/>*/}
                <Route path="/report" component={Report} />
                <Route path="/forms" component={Formiks}/>
                <Route path="/handson" component={HotApp} />
            </Switch>
        </BrowserRouter>
    )
};

export default mainBody