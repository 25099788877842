export default {
    name: 'Fleet Menus',
    roles: ['superuser', 'user'],
    children: [
        {
            name: 'Vehicles',
            target: 'PROGRAM vehicles',
            fa: 'fa-car',
            children: [
                {
                    name: 'All Vehicles',
                    target: 'PROGRAM vehicles',
                    fa: 'fa-car',
                }, {
                    name: 'Makes',
                    target: 'PROGRAM vehicle make',
                    fa: 'fa-car-side',
                }, {
                    name: 'Models',
                    target: 'PROGRAM vehicle model',
                    fa: 'fa-car-side',
                }
            ]
            // }, {
            //     name: 'Card Read / Write',
            //     target: 'rfid',
            //     fa: 'fa-microchip',
            // }, {
            //     name: 'Insurance',
            //     target: 'PROGRAM insurance',
            //     fa: 'fa-scroll',

            // }, {
            //     name: 'Service',
            //     target: 'PROGRAM service',
            //     fa: 'fa-wrench',
            // }, {
            //     name: 'Service Receipts',
            //     target: 'PROGRAM service receipts',
            //     fa: 'fa-file-invoice',
            // }, {
            //     name: 'Service Repairs',
            //     target: 'PROGRAM service repairs',
            //     fa: 'fa-cogs',
        }, {
            name: 'Drivers',
            target: 'PROGRAM drivers',
            fa: 'fa-users',
            children: [
                {
                    name: 'All Drivers',
                    target: 'PROGRAM drivers',
                    fa: 'fa-users',
                }, {
                    name: 'Vehicle Assigned',
                    target: 'vehicles_assigned',
                    fa: 'fa-truck-pickup',
                }
            ]
        // }, {
        //     name: 'Tracker',
        //     target: 'PROGRAM tracker',
        //     fa: 'fa-microchip',
        }, {
            name: 'GPRS Locations Summary',
            target: 'recent_locations',
            fa: 'fa-map-marker',
        }, {
            name: 'GPRS Locations Received',
            target: 'PROGRAM gprs locations',
            fa: 'fa-map-marker',
            // }, {
            //     name: 'Tracker Location',
            //     target: 'PROGRAM tracker location',
            //     fa: 'fa-map-marker',
            // }, {
            //     name: 'Reports',
            //     target: 'main_m',
            //     children: []
        }, {
            name: 'Tracker Logs',
            target: 'tracker_logs',
            fa: 'fa-map-marker',
        },
    ]
}