import React from 'react'
import driver_vehicle, {returned} from "./driver_vehicle";
// import insurance from "./insurance";
// import vehicle_service from "./vehicle_service";
// import tracker from "./tracker";
// import tracker_location from "./tracker_location";
import foreign_keys from "../foreign_keys";
import gprs_locations from "./gprs_locations";
import gprs_locations_recent from "./gprs_locations_recent";

let
    division = {name:'Division', type:'select', id:'division', source:'vehicles.division', options:{value:'division'}, creatable: true, span:1},
    department = {name:'SubRegion/Department', type:'select', id:'department', source:'vehicles.department', options:{value:'department'}, creatable: true, span:1}

export default {
    name: 'PROGRAM vehicles',
    tab_name: 'Vehicles',
    type: 'table',
    table: 'vehicles',
    fa: 'car',
    title: ['make', 'model', 'civilian_plate_no'],
    lines: [
        [
            {name: 'Vehicle Id', id: 'vehicles.id', type: 'text', span: 1, disabled: true, showInTable: false, showInModal: false},
            {name: 'Chassis Number', id: 'vehicles.chassis_no', type: 'text', span: 1, showInTable: false},
            {name: 'Vehicle Make', id: 'vehicles.make', type: 'text', showInModal: false, span: 1},
            {name: 'Vehicle Model', id: 'vehicles.model', type: 'text', showInModal: false, span: 1},
            {name: 'Vehicle Make', id: 'vehicles.make', type: 'select', showInTable:false, source: 'make.description', options: {value: 'description', name: 'description'}, span: 1},
            {name: 'Vehicle Model', id: 'vehicles.model', type: 'select', showInTable:false, source: 'model.description', options: {value: 'description', name: 'description'}, span: 1},
            {...division, showInModal: false},
            {...department, showInModal: false},
            {name: 'Vehicle Description', id: 'vehicles.description', type: 'text', span: 1}
        ], [
            {name: 'GOK Plate Number', id: 'vehicles.gok_plate_no', type: 'text', span: 1},
            {name: 'Civilian Plate Number', id: 'vehicles.civilian_plate_no', type: 'text', span: 1},
            {name: 'Tracker IMEI', id: 'vehicles.imei', type: 'text', span: 1, required: true},
            {name: 'Tracker Phone', id: 'vehicles.phone_no', type: 'text', span: 1, required: true},
        ], [
            {name: 'Year of Manufacture', id: 'vehicles.yom', type: 'select', ...foreign_keys.year(), span: 1},
            {name: 'Engine No', id: 'vehicles.engine_no', type: 'text'},
            {name: 'Vehicle Colour', id: 'vehicles.colour', type: 'select', source:'vehicles.colour', options:{value:'colour', name:'colour'}, creatable:true, span:1},
            {name: 'Rating (cc)', id: 'vehicles.rating', type: 'text'},
        ], [
            {name: 'Tyre Size', id: 'vehicles.tyre_size', type: 'text'},
            {name: 'Battery Size', id: 'vehicles.battery_size', type: 'text'},
            {name: 'Fuel Type', id: 'vehicles.fuel_type', type: 'select', options:[{value:'Petrol'},{value:'Diesel'}], creatable: true},
            {name: 'Service Interval', id: 'vehicles.service_interval', type: 'text'},
        ], [
            {name: 'Acquisition Mode', id: 'vehicles.acquisition', type: 'select', options:[{value:'Purchase'}, {value:'Donation'}], creatable: true},
            {name: 'Purchase Date', id: 'vehicles.purchase_date', type: 'date'},
            {name: 'Purchase Price', id: 'vehicles.purchase_price', type: 'currency'},
            {name: 'Purchased From', id: 'vehicles.purchased_from', type: 'select', source:'vehicles.purchased_from', options:{value:'purchased_from'}, creatable: true, span:1},
        ], [
            {name:'Vehicle Function', type:'select', id:'function', options:[{value:'Administrative'}, {value:'Operational'}], creatable:true, span:1},
            {name: 'Vehicle Condition', id: 'vehicles.condition', type: 'select', source:'vehicles.condition', options:{value:'condition'}, creatable: true, span:1},
            {name:'Status', type:'select', id:'status', options:[{value:'NIS'}, {value:'Disposed'}], creatable:true, span:1},
            {name:'Disposal Date', type:'date', id:'disposal_date', span:1}
        ], [
            {name:<u>Allocated To</u>, type:'heading', span:3},
        ], [
            {...division, showInTable: false},
            {name:'Region', type:'select', id:'region', source:'vehicles.region', options:{value:'region'}, creatable: true, span:1},
        ], [
            {...department, showInTable: false},
            {name:'Vehicle Usage', id:'usage', type:'select', options:[{value:'Individual'}], creatable: true},
            {name:'Alloted Person', type:'text', id:'alloted_person'},
        ], [
            {name: 'Current Insurance Policy Number (Optional)', id: 'vehicles.policy_no', type: 'select', source: 'insurance.policy_number', span: 1, showInTable:true},
            {name: 'Date of Expiration', id: 'insurance.policy_number', type: 'reference', reference: 'policy_no', span: 1},
        ],
        // [{...driver_vehicle, tab_name: 'Assign Driver', fa:'car-side'}],
        // [{...returned, tab_name: 'Return', fa:'car-side fa-flip-horizontal'}],
        // [insurance],
        // [vehicle_service],
        // [tracker],
        // [{...tracker_location, tab_name:'Locations'}],
        [{...gprs_locations_recent, label: 'Current Location', fa:'map-pin', pkey: "imei", fkey: "imei"}],
        // [{...gprs_locations, label: 'Location | Plate'}],
        // [{...gprs_locations_recent, label: 'Position | IMEI', pkey:'imei', fkey:'imei', fa:'map-pin'}],
        [{...gprs_locations, fkey:'imei', pkey:'imei', label:'Previous Locations'}],

    ]
}
/*
* Todo
*   trigger to send notification if vehicles insurance number is due for renewal*/