import React, {PureComponent} from 'react'
import {database_table, global_token, setEntries} from '../../../store/actions/pageactions'
import {signOut} from '../../../store/actions/authactions'
import {generateHeaders, get_record_values, formToHeaders, fix_number, getForm} from './utilities'
import API from '../../../store/api'
import {build_url} from "../form/utilities";
import {server_error} from "../errors";
import Modal from "../form/modal";
import FormSuper from "../form/FormSuper";
import {hideTableOnFormOpen, oneItemAsForm, useModal} from "../../../custom/configs";
import Importation from "../form/imports";
import configs from "../../../custom/configs";
import ReportIframe from '../report_iframe'
import axios from 'axios'

let report_target = configs.report_target

export default class extends PureComponent {
    state = {
        table_data: null,
        page: null,
        results_per_page: 50,
        width: 0,
        height: 0,
        order_by_direction: false
    }

    tableRef = React.createRef()
    /* Start Form Things*/
    updateDisplayState = (params) => {
        this.setState(params)
    }

    updateDisplayStateAndRefresh = (params) => {
        this.setState(state => params, () => this.api_get_data())
    }

    hideModal = () => {
        this.setState({show_modal: false});
    };

    setIndex = (index, change_modal_key) => {
        this.handleRecord(null, index, 'details', change_modal_key)
    }

    refreshTable = total => {
        this.api_get_data()
    }

    clear_search = () => {
        this.setState(() => ({search_params: null}), () => {
            if (this.props.history.location.pathname.includes(this.props.target)) {
                this.props.history.replace({pathname: this.props.history.location.pathname, search: `?page=${this.state.page}`})
            }
            this.refreshTable()
        })
    }

    FormDisplay = (props) => {
        let Component = useModal ? Modal : FormSuper,
            {hideModal, updateDisplayStateAndRefresh, updateDisplayState, setIndex, refreshTable, handleDelete, clear_search} = this,
            {show_modal, modal_key, form_state, search_params, record_index, table_data} = this.state,
            // record_values = this.state.table_data.objects.find((item, index)=> index===record_index),
            {target, page_name, showAlert, default_fk, form, parent_values = {}} = this.props,
            height = ((this.props.match.url.replace('/page/', '') && !this.is_a_report()) === this.props.target) ? this.state.height : null,
            params = {showAlert, show_modal, target, hideModal, updateDisplayStateAndRefresh, updateDisplayState, setIndex, refreshTable, page_name, default_fk, form_state, search_params, record_index, height, form, handleDelete, parent_values, clear_search}

        if (this.is_a_report()) {
            return (show_modal && <Component key={modal_key} {...params} form_state='insert'/>)
        } else {
            const
                {record_values = get_record_values(table_data, record_index)} = this.state,
                // {record_values = {...get_record_values(table_data, record_index), ...(form_state === 'search'? search_params: {})}} = this.state,
                {primary_key: primary_key_column, primary_keys: primary_key_columns, num_results} = this.state.table_data,
                params2 = {...params, primary_key_column, primary_key_columns, num_results}
            return (show_modal || this.is_a_report() || ['search','insert'].includes(form_state)) && <Component key={modal_key} {...params2} record_values={record_values}/>
        }
    }

    rows_as_form = _ => {
        let
            {table_data} = this.state,
            {form: {rows_as_form, customComponent}} = this.props,
            {oneItemAsForm: one_item_as_form = oneItemAsForm} = this.props.form
        return (rows_as_form || (one_item_as_form && table_data && table_data.objects.length === 1 && table_data.page === 1 && [null, undefined, 'form'].includes(customComponent))) ? true : false
    }

    /* End Form Things*/

    /*constructor(props) {
        super(props)
        // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        // console.error('PROPS', {con_props: props, inh_props:this.props})
    }*/

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight - 225 - (window.innerWidth <= 1280 ? 50 : 0)});
    }

    componentDidMount() {
        this.updateWindowDimensions();
        let {beforeTable = () => null, afterTable = () => null} = this.props.form
        this.BeforeTable = beforeTable.bind(this)
        this.AfterTable = afterTable.bind(this)
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('keyup', this.keyListener)
        // this.props.api_get_data()
        if (this.props.data) {
            this.setState(state => ({
                table_data: {
                    total_pages: 1,
                    num_results: 1,
                    page: 1,
                    objects: this.props.data,
                    primary_key: ''
                },
                page: 1,
                // headers_all: generateHeaders(this.props.target),
                //  ...(this.props.form ? formToHeaders(this.props.form) : generateHeaders(target))
            }))
        } else {
            let callback = () => {
                if (this.state.table_data && this.state.table_data.num_results === 1) {
                    // this.props.form === undefined && this.handleDetails(null, 0)
                    this.setState({show_modal: false})
                }
            }
            !this.is_a_report() && this.api_get_data({callback})
        }
        if (this.is_a_report()) {
            this.setState({show_modal: true});
        }

        // let {form} = this.props
        // if (form.refreshEvery) {
        //     this.refreshInterval = setInterval(this.api_get_data, form.refreshEvery)
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.entries !== this.props.entries) {
            this.refreshTable(this.state.total_pages)
        }
        if (prevProps.isAuthenticated === false && this.props.isAuthenticated === true && !this.is_a_report()) {
            this.api_get_data()
        }

        if (this.state.page !== prevState.page) {
            this.tableRef.current && this.tableRef.current.scrollTo(0, 0)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('keyup', this.keyListener)
        let {form} = this.props
        if (form.refreshEvery && this.refreshInterval) {
            clearTimeout(this.refreshInterval)
        }
    }

    keyListener = e => {
        let {show_modal, page, table_data} = this.state
        if (show_modal !== true && page && table_data && table_data.num_results > 1) {
            if (e.code === 'ArrowRight')
                this.api_get_data({page: page + 1})
            if (e.code === 'ArrowLeft')
                this.api_get_data({page: page - 1})
        }

    }

    api_get_data = (params = {}) => {
        let logOut = () => {
            this.props.signOut()
            configs.redirect_to_login && this.props.history.push('/?next=' + this.props.match.url)
        }
        if (!this.props.isAuthenticated) {
            logOut()
        }
        let
            {token = global_token, target, showAlert, form, url = form.url, table = form.table || database_table(target), default_fk = {}} = this.props,
            {search_params: form_search_params = {}} = form,
            {search_params: state_search_params = {}} = this.state,
            get_params = param => {
                let params = this.props.history.location.search.split('&') || []
                if (param === 'page') {
                    // if (Object.keys(default_fk).length > 0) {
                    //     return null
                    // }
                    if ([undefined, null].includes(default_fk) || Object.keys(default_fk).length === 0) {
                        return params[0].replace('?page=', '')
                    } else {
                        return null
                    }
                } else if (param === 'search_params') {
                    let state_search = {...state_search_params, ...form_search_params, ...default_fk}
                    if (Object.keys(state_search).length > 0) {
                        return state_search
                    } else {
                        // if (Object.keys(default_fk).length > 0) {
                        //     return {}
                        // }
                        if ([undefined, null].includes(default_fk) || Object.keys(default_fk).length === 0) {
                            let search = params.find(item => item.includes(param))
                            if (search !== undefined) {
                                try {
                                    return JSON.parse(unescape(search.replace('search_params=', '')))
                                } catch (e) {
                                    return {}
                                }
                            } else {
                                return {}
                            }
                        } else {
                            return {}
                        }
                    }
                } else {
                    return {}
                }
            },
            {page = this.state.page || get_params('page') || 1, /*url = this.props.url,*/ callback} = params,
            search_params = get_params('search_params'),
            results_per_page = this.props.entries || this.state.results_per_page,
            // Todo Remember to remove token during production
            per_page = results_per_page || 20,
            get_page = page,
            {order_by, order_by_direction} = this.state,
            table_order_by = order_by && [order_by, order_by_direction],
            table_order_params = table_order_by ? `&order_by=${JSON.stringify(table_order_by)}` : '',
            get_search_params = Object.keys(search_params).length > 0 ? `&search_params=${escape(JSON.stringify(search_params))}` : '',
            // get_url = url ? `${url}${table_order_params}` : `/api/v1/${table}?page=${get_page}&results_per_page=${per_page}${get_search_params}${table_order_params}`,
            get_url = url || `/api/v1/${table}?page=${get_page}&results_per_page=${per_page}${get_search_params}${table_order_params}`,

            api_success = response => {
                if (response.data.objects) {
                    this.setState(state => ({
                            table_data: response.data,
                            page: (form.refreshEvery !== undefined ? state.page : page),
                            search_params: search_params,
                            table: table,
                            show_modal: (response.data.num_results === 1 && oneItemAsForm) ? false : state.show_modal
                        }),
                        callback && callback
                    )
                    if ([undefined, null].includes(default_fk) || Object.keys(default_fk).length === 0) {
                        this.props.history.replace({pathname: this.props.history.location.pathname, search: `?page=${page}${Object.keys(search_params).length > 0 ? `&search_params=${JSON.stringify(search_params)}` : ''}`})
                    }
                } else {
                    /* Find a way to check if the response is a redirect*/
                    // logOut()
                    this.props.showAlert(true, 'Response has no data objects')
                }
                if (form.refreshEvery) {
                    this.refreshInterval = setTimeout(this.api_get_data, form.refreshEvery)
                }
            },
            on_no_data = () => {
                let {table_data} = this.state
                this.setState(state => ({
                    table_data: {
                        total_pages: table_data ? table_data.total_pages : 0,
                        num_results: 0,
                        page: page,
                        objects: [],
                        primary_key: table_data ? table_data.primary_key : null
                    },
                    // page: page
                }))
            },
            api_error = error => {
                // console.log('ERROR OCCURRED', {error_keys: Object.keys(error), value: error.toJSON(), response: error.response})
                if (axios.isCancel(error)) {
                    console.log('Previous request cancelled', error.message)
                    return
                }
                let message = (() => {
                    if (error.response) {
                        return error.response.data
                    } else if (error.toJSON) {
                        return error.toJSON().message
                    } else {
                        return JSON.stringify(error)
                    }
                })()
                if (error.response && [302, 401].includes(error.response.status)) {
                    console.log('REDIRECTING TO LOGIN')
                    logOut()
                } else {
                    on_no_data()
                    showAlert && showAlert(true, message)
                }
            },
            api_catch = error => {
                if (axios.isCancel(error)) {
                    console.log(error.message)
                }
                let message = (() => {
                    if (error.length) {
                        return JSON.stringify(error)
                    } else {
                        return 'Error Caught'
                    }
                })()
                // logOut()
                console.log('ERROR CAUGHT', error, {error_keys: Object.keys(error), value: '', response: ''})
                showAlert && showAlert(true, message)
            }
        if (!table) {
            console.log('NO FORM HAS BEEN DESIGNED FOR THIS PAGE')
        }
        API.defaults.headers.common['Authorization'] = `JWT ${token}`;
        if (get_url) {
            this.cancelTokenSourceCancel && this.cancelTokenSourceCancel('CANCELLED');
            axios.get(get_url, {
                cancelToken: new axios.CancelToken(c => {
                    this.cancelTokenSourceCancel = c;
                })
            }).then(api_success, api_error).catch(api_catch)
        } else {
            console.log('NO TABLE DEFINED IN GETTING TABLE VALUES')
        }
        {/* ALSO seems to work
            if (get_url) {
                this.cancelTokenSource && this.cancelTokenSource.cancel('CANCELLED');
                this.cancelTokenSource = axios.CancelToken.source()
                axios.get(get_url, {
                    cancelToken: this.cancelTokenSource.token
                }).then(api_success, api_error).catch(api_catch)
            } else {
                console.log('NO TABLE DEFINED IN GETTING TABLE VALUES')
            }
            */
        }
    }

    change_page = (record_index, act) => {
        let page,
            {form} = this.props
        if (record_index > this.state.table_data.objects.length - 1) {
            // console.log('Page Con 1: Getting page', {page: (this.state.table_data.page + 1) % this.state.table_data.total_pages})
            page = (this.state.table_data.page + 1) % (this.state.table_data.total_pages + 1)
            this.api_get_data({page: page, callback: () => act(0)})
        } else if (record_index < 0) {
            page = (this.state.table_data.page - 1) % this.state.table_data.total_pages || this.state.table_data.total_pages
            // console.log('Page Con 2: Getting page', {page: (this.state.table_data.page - 1) % this.state.table_data.total_pages || this.state.table_data.total_pages})
            this.api_get_data({page: page, callback: act})
        } else {
            act()
        }
        (form && form.refreshEvery !== undefined) && this.setState({page})
    }

    handleRecord = (e, record_index, form_state, change_modal_key) => {
        e && e.preventDefault()
        let
            act = def_index => {
                let
                    {target} = this.props,
                    {table_data, page: table_page} = this.state,
                    record_index_corrected = (def_index !== undefined) ? def_index : fix_number(record_index, table_data.objects.length),
                    state_data = {record_index: record_index_corrected, form_state, table_page, show_modal: true, record_values: get_record_values(table_data, record_index_corrected, target)}
                state_data.modal_key = Math.random()
                if (change_modal_key) {
                    state_data.modal_key = Math.random()
                }
                this.setState(state_data)
            }
        this.change_page(record_index, act)

    }

    handleEdit = (e, record_index) => {
        e && e.preventDefault()
        this.handleRecord(e, record_index, 'update')
    }

    handleDetails = (e, record_index) => {
        e && e.preventDefault()
        this.handleRecord(e, record_index, 'details')
    }

    handleDelete = (e, record_index) => {
        e.preventDefault()
        let
            {table = database_table(this.props.target), target, tab_name} = this.props.form,
            {table_data} = this.state,
            record_values = get_record_values(table_data, record_index, target) || this.state.record_values,
            primary_key_columns = table_data.primary_keys,
            url = build_url(primary_key_columns, record_values, table)
        if (window.confirm(`Are you sure you want to delete record id - ${primary_key_columns.map(pk => record_values[pk])} from ${tab_name || table}`)) {

        } else {
            return
        }

        API.delete(url).then(
            response => {
                // this.props.showAlert(true, 'Successfully deleted', response.status)
                this.props.showAlert(true, response.data, response.status)
                this.api_get_data()
                this.setState({show_modal: false})
            },
            error => {
                if (error.response.status === 401) {
                    console.log('REDIRECTING TO LOGIN')
                    this.props.signOut()
                    configs.redirect_to_login && this.props.history.push('/?next=' + this.props.match.url)
                } else {
                    console.log('Inner error handler')
                    let message = server_error(error)
                    this.props.showAlert(true, message)
                }
            }
        )
    }

    actions = () => {
        let {handleEdit, handleDelete, handleDetails} = this
        return [
            {name: 'Details', fa: 'eye', action: handleDetails, color: 'primary'},
            {name: 'Edit', fa: 'pencil', action: handleEdit, color: 'success'},
            {name: 'Delete', fa: 'trash', action: handleDelete, color: 'danger'},
        ]
    }

    is_a_report = () => {
        let {target, form} = this.props
        return form.type === 'report' || target.split(" ")[0] === 'REPORT'
    }

    DisplayBody = (props) => {
        let
            {HeadSection = null, api_get_data} = this,
            is_a_report = this.is_a_report(),
            {target, form, showAlert, default_fk} = this.props,
            {show_modal, table_data, show_import, height} = this.state,
            {oneItemAsForm: one_item_as_form = oneItemAsForm} = this.props.form,
            style = {height: this.props.default_fk ? (is_a_report ? this.state.height - 20 : null) : this.state.height + 40, overflow: 'scroll', minHeight:(table_data && table_data.num_results) ? null: '100px'}
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        {(table_data || is_a_report) && this.FormDisplay({style: style})}
                        {table_data && this.BeforeTable && <this.BeforeTable {...{...this.props, ...this.state}}/>}
                        <Importation show_import={show_import} form={form} showAlert={showAlert} setState={obj => this.setState(obj)} api_get_data={api_get_data} height={height} signOut={signOut}/>
                    </div>
                </div>
                <div className="row" style={{display: (hideTableOnFormOpen && !is_a_report && (show_modal || show_import) && !useModal) || show_import ? 'none' : 'block'}}>
                    <div className="col-md-12">
                        {(table_data && table_data.num_results === 1 && ['form', undefined].includes(this.props.form.customComponent) && one_item_as_form) ?
                            props.children
                            :
                            <div className='box box-primary'>
                                <div className="box-header with-border">
                                    <HeadSection/>
                                </div>
                                <div className="box-body " style={style} ref={this.tableRef}>
                                    {props.children}
                                    {is_a_report && <ReportIframe key={this.state.report_key} src={default_fk ? null : `/reports/${target}`} style={style} showAlert={this.props.showAlert} name={report_target} show_modal={show_modal} updateDisplayState={this.updateDisplayState}/>}
                                </div>
                            </div>}
                    </div>
                </div>
                {this.state.table_data && this.AfterTable &&
                <div className="row">
                    <div className="col-md-12">
                        <this.AfterTable {...{...this.state, ...this.props}}/>
                    </div>
                </div>
                }
            </div>
        )
    }

    HeadSection = () => null  /* Should be implemented*/

    form_headers = () => null  /* Should be implemented*/

    form_rows = () => null  /* Should be implemented*/
}

export const mapStateToProps = (state, ownProps) => {
    let {target, default_fk, form = getForm(target)} = ownProps,
        {headers, headers_all} = form ? formToHeaders(form, default_fk) : generateHeaders(target, default_fk)
    return {
        headers,
        headers_all,
        form,
        ...state.pageReducer,
        ...ownProps,
        ...state.authReducer,
        entries: state.pageReducer.entries
    }
};

export const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
        setEntries: entries => dispatch(setEntries(entries)),
    }
};