import {element_id} from "../../components/page_route/table/utilities";
import {camelcase} from "../../components/page_route/table/utilities";
import API from '../../store/api'

const select = {type: 'select'}

const full_name_preprocessor = (state, props) => ({value: `${camelcase(props.f_name || '')} ${camelcase(props.m_name || '')} ${camelcase(props.l_name || '')}`})

export default {
    hr_division: {type: 'select', source: 'hr_division.description', options: {value: 'unit_code', name: 'description'}},
    hr_county: {source: 'hr_county.county_name', options: {value: '', name: 'county_name'}, type: 'select'},
    hr_subcounty: {source: 'hr_subcounty.subcounty_name', options: {value: 'subcounty_name'}, type: 'select'},
    hr_subdivision: {type: 'select', source: 'hr_subdivision.description', options: {value: 'unit_code', name: 'description'}},
    hr_department: {type: 'select', source: 'hr_department.description', options: {value: 'unit_code', name: 'description'}},
    hr_orgunit: {type: 'select', source: 'hr_orgunit.description', options: {value: 'unit_code', name: 'description'}},
    pay_location: {type: 'select', source: 'pay_location.loc_name', options: {value: 'location', name: 'loc_name'}},
    pay_regions: {type: 'select', source: 'pay_regions.region_name', options: {value: '', name: 'region_name'}},
    pay_gradecode: {type: 'select', source: 'pay_gradecode.description', options: {value: '', name: 'description'}},
    pay_engagement: {source: 'pay_engagement.description', options: {value: '', name: 'description'}, type: 'select'},
    pay_employee: {source: 'pay_employee.full_name', options: {value: '', name: 'full_name'}, type: 'select'},
    yes_no: {type: 'select', source:'', options: [{value: 'Yes'}, {value: 'No'}]},
    yes_no_boolean: {type: 'select', source:'', options: [{value: true, name:'Yes'}, {value: false, name:'No'}]},
    pay_earn_code: {type: 'select', source: 'pay_earn_code.description',},
    pay_dedc_code: {type: 'select', source: 'pay_dedc_code.description',},
    pay_loan_type: {type: 'select', source: 'pay_loan_type.description',},
    // pay_payloan: {type: 'select', source: 'pay_payloan.p_dedc_code',},
    // pay_paydedc: {type: 'select', source: 'pay_paydedc.dedc_code'},
    pay_jobcode: {source: 'pay_jobcode.job_desc', type: 'select', options: {name: 'job_desc'}},
    hr_jobcode_group: {source: 'hr_jobcode_group.group_desc', type: 'select', options: {name: 'group_desc'}},
    selection: {name: 'Enter Selection ...', type: 'heading'},
    hr_fld_of_study: {type: 'select', source: 'hr_fld_of_study.field_name', options: {name: 'field_name'}},
    hr_educlevel: {...select, source: 'hr_educlevel.level_desc', options: {name: 'level_desc'}},
    hr_discipline: {type: 'select', source: 'hr_discipline.offence_code'},
    hr_dis_offence: {...select, options: {value: 'offense_code', name: 'description'}, source: 'hr_dis_offence.description'},
    hr_dis_action: {...select, source: 'hr_dis_action.description', options: {name: 'description'}},
    hr_lvtype: {...select, source: 'hr_lvtype.type_desc'},
    int_on_int: [
        {value: 'Y', name: 'calc. interest on total balance'},
        {value: 'N', name: 'on principal bal. only'}
    ],
    month_num: {
        type: 'select',
        source: '',
        options: [
            {value: '01', name: 'January'},
            {value: '02', name: 'February'},
            {value: '03', name: 'March'},
            {value: '04', name: 'April'},
            {value: '05', name: 'May'},
            {value: '06', name: 'June'},
            {value: '07', name: 'July'},
            {value: '08', name: 'August'},
            {value: '09', name: 'September'},
            {value: '10', name: 'October'},
            {value: '11', name: 'November'},
            {value: '12', name: 'December'},
        ]
    },
    month_name: {
        type: 'select',
        source:'',
        options: [
            {value: 'January'},
            {value: 'February'},
            {value: 'March'},
            {value: 'April'},
            {value: 'May'},
            {value: 'June'},
            {value: 'July'},
            {value: 'August'},
            {value: 'September'},
            {value: 'October'},
            {value: 'November'},
            {value: 'December'},
        ]
    },
    year: (start = 1970, stop = new Date().getFullYear()) => {
        return {
            type: 'select', options: Array.from(Array(stop - start + 1).keys()).map(item => {
                return {value: (item + start).toString()}
            })
        }
    },
    output: {
        name: 'Output', id: 'output',
        type: 'select', options: [
            {value: 'XLSX', name: 'Excel'}, {value: 'XLSO', name: 'Excel Online (Read Only)'}, {value: 'CSV', name: 'CSV'}, {value: 'PDFV', name: 'PDF View'}, {value: 'PDF', name: 'PDF'}, {value: 'PLAIN', name: 'Plain Text'},{value: 'WEB', name: 'Web Page'},
        ]
    },
    academic_category: {...select, options: [{value: "Internal"}, {value: "Academic"}, {value: "Professional"}],},
    educ_include: {...select, options: [{value: 'Current / New only',}, {value: 'Terminated only',}, {value: 'All, incl.Terminated'}]},
    create_reference: obj => {
        let {source, id, name, parentSource} = obj
        return {id: source, reference: id, name, type: 'reference', parentSource}
    },
    create_reference2: obj => {
        let [fk, ...others] = obj
        return {id: fk.source, reference: others[0], name: others[1], type: 'reference'}
    },
    increment_preprocessor: (state, props) => {
        if (props.form_state !== 'search') {
            let {id, value} = props
            id = element_id(id)
            !Boolean(value) && API.get(`/api/v1/${props.table}?search_params=${JSON.stringify(props.default_fk)}&columns=${JSON.stringify([id])}`).then(
                (response) => {
                    let max = response.data.objects.length ? Math.max(...response.data.objects.map(item => item[id])) : 0
                    props.handleChange({
                        target: {
                            id,
                            value: max + 1
                        }
                    })
                },
                (error) => {
                    props.showAlert(true, error)
                }
            )
        }
    },
    full_name_element: {col: 4, type: 'calculated', id: 'pay_employee.full_name', name: 'Full Name', preprocessor: full_name_preprocessor, showInTable: true, showInModal: false}
}
