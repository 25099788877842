// https://dev.to/achowba/building-a-modal-in-react-15hg
// https://medium.com/@lucksp_22012/pure-react-modal-6e562a317b85
import React from 'react';
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {FormSuper, mapStateToProps, mapDispatchToProps} from "./FormSuper";

export class Modal extends FormSuper{
    modal_body = ({children}) => {
        let is_a_report = this.is_a_report(),
            {show_modal, size, max_modal, show_header=true} = this.props,
            modal_show = show_modal && 'in',
            modal_size = size || is_a_report ? 'modal-md' : 'modal-lg',
            modal_style = show_modal ? {display: 'block', overflow: 'scroll', zIndex: '10000'} : null,
            my_style = max_modal ? {width: '80%'} : {}
        return ReactDOM.createPortal((
            <div className={`modal fade ${modal_show}`} id="fa_modal_window" tabIndex="100" role="dialog" aria-labelledby="fa_modal_label" style={modal_style} /*onClick={closeModal}*/>
                <div className={`modal-dialog modal-dialog-centered ${modal_size}`} role="document" style={{...my_style, borderTop: '3px solid #00a65a'}}>
                    <div className="modal-content">
                        {show_header &&
                        <div className="modal-header" style={{padding: '0 15px'}}>
                            <div style={{margin:'5px 0 5px 0'}}>
                            <this.form_header/>
                            </div>
                        </div>}
                        <div className="modal-body" style={this.body_style}>
                            {children}
                        </div>
                        {/*<div className="modal-footer" style={{textAlign: 'left'}}>
                        </div>*/}
                    </div>
                </div>
            </div>
        ), document.body)
    }
    closeModal = (e) => {
        if (e.target === e.currentTarget) {
            this.props.hideModal()
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modal))

