// https://dev.to/achowba/building-a-modal-in-react-15hg
// https://medium.com/@lucksp_22012/pure-react-modal-6e562a317b85
import React, {PureComponent, useState, useEffect} from 'react';
import TextField from './formelements/textfield'
import SelectField from './formelements/select'
import TextArea from './formelements/textarea'
import FormTable from './formelements/form_table'
import YMonth from './formelements/ymonth'
import MultiSelectField from './formelements/multiselect'
import Calculated from './formelements/calculated'
import {global_token} from '../../../store/actions/pageactions'
import {element_id, getForm, calcSize} from '../table/utilities'
import File from "./formelements/file";
import {build_primary_key_value, exact_primary_key_column, isSubset, build_default_fk, build_url} from './utilities'
import {icon_generator} from "../../../custom/forms/icons";
import configs, {buttons_top, subTableBelow as subtablepos} from "../../../custom/configs";

const log_where = false,
    report_target = configs.report_target

let subTableBelow = (form = {}) => {
    return form.subtablebelow || subtablepos
}

export class FormGenerator extends PureComponent {
    state = {}
    style = {} //{width: '90%'}
    body_style = {}
    active = -1
    form_table = []

    variables = {}

    setVariables = obj => {
        this.variables = {...this.variables, ...obj}
        this.forceUpdate()
    }

    self = _ => {
        let {primary_key_columns, record_values = {}, form} = this.props
        return record_values && Object.keys(record_values).length > 0 ? build_url(primary_key_columns, record_values, form.table) : null
    }


    // Todo Find a way to reset the state preventing
    componentDidMount() {
        // window.addEventListener('keyup', this.keyListeners);
        log_where && console.log('Page Component Mounted > Modal Component Mounted')
        let {form = {}, default_fk = form.default_fk || form.search_params, form_state, search_params} = this.props
        if (default_fk && form_state === 'insert') {
            this.setState(default_fk)
        }
        if (search_params && (/*form_state === 'search' || */ this.is_a_report())) {
            this.setState(search_params)
        }
        if (form.alwaysProcess) {
            this.setState(form.processor.bind(this)(this.state, this.props))
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {form_state, target, form = getForm(target), default_fk} = this.props,
            {processor, condition} = form
// alert(['insert', 'update'].includes(form_state) && processor !== undefined && prevState !== this.state)
        if (
            (['insert', 'update'].includes(form_state) && processor !== undefined && prevState !== this.state) ||
            (form.alwaysProcess === true)
        ) {
            let state = {...this.props.record_values, ...this.variables, ...this.state},
                PrevState = {...this.props.record_values, ...this.variables, ...prevState}
            if (condition === undefined || condition(state, this.props, PrevState, prevProps))
                this.setState(processor.bind(this)(state, this.props, PrevState, prevProps, this))
        }

        if (default_fk && form_state === 'insert' && !isSubset(this.state, default_fk)) {
            this.setState(default_fk)
        }
    }


    multiSelectHandleChange = (object) => {
        console.log('New Object', object)
        if (['insert', 'update', 'search'].includes(this.props.form_state)) {
            let key = Object.keys(object)[0],
                appendCallback = () => {
                    this.setState(state => {
                            let new_value = state[key]
                            if (Array.isArray(new_value)) {
                                new_value.push(object[key])
                            }
                            return {[key]: object[key]}
                        },
                        () => {
                            console.log('State after update', this.state)
                        })
                }
            if (!(this.state[key])) {
                this.setState(state => ({
                        [key]: []
                    }), appendCallback
                )
            } else {
                appendCallback()
            }
        }
    }

    createInput = (fullId, form_element, id, form_value) => {
        log_where && console.log('Page Component Mounted > Modal Component Mounted > Populating Form Elements')
        let {record_values = this.state, form_state, primary_key_columns, form, token = global_token, num_results, search_params = {}} = this.props,
            {handleChange, onPressEnter} = this,
            select_field = ({handleChange, ...params}) => {
                handleChange = (object) => {
                    if (['insert', 'update', 'search'].includes(this.props.form_state)) {
                        this.setState(object)
                    }
                }
                return <SelectField  {...params} handleChange={handleChange}/>
            }

        if (!Boolean(form_element.type) && form.table && fullId && fullId.split('.')[0] !== form.table) {
            // Setting form element to reference if type is not defined and table is not same as form_element_id
            form_element.type = 'reference'
        }
        if (form_element.showInModal !== undefined && !form_element.showInModal) {
            console.log('DO NOT CREATE')
            return
        }
        if (form_state === 'insert' && form_element.defaultValue !== undefined && {...search_params, ...record_values, ...this.state}[id] === undefined) {
            Promise.resolve(form_element.defaultValue).then(value => {
                this.setState({[id]: value.data !== undefined ? value.data : value})
            })
        }
        let required = form_state === 'search' ? null : form_element.required || null,
            {default_fk, showAlert} = this.props,
            is_a_report = this.is_a_report(),
            value = form_value !== undefined ? form_value : form_element.defaultValue || null,
            setState = object => {
                if (['insert', 'update', 'search'].includes(this.props.form_state)) {
                    this.setState(object)
                }
            },
            params = {...form_element, id, handleChange, value, required, default_fk, showAlert, fullId, table: form.table, form_values: {...record_values, ...this.variables, ...this.state}, form_state, setState, onPressEnter, primary_key_columns, setVariables: this.setVariables, errors: this.errors, is_a_report},
            makeTable = _ => {
                if (['update', 'details'].includes(form_state)) {
                    let default_fk = build_default_fk(form_element, {...record_values, ...this.state}, primary_key_columns),
                        icon = form_element.icon || icon_generator(form_element.fa || configs.default_icon_fa, form_element.label || form_element.tab_name, form_element.icon_type),
                        tab_name = form_element.tab_name || form_element.name || form_element.table,
                        params = {form: form_element.lines ? form_element : getForm(form_element.target), target: form_element.name, default_fk, id, showAlert, value, required, /*, url:url_2()*/ parent_values: {...record_values, ...this.variables, ...this.state}},
                        component = FormTable
                    /*,
                    url_2 = () => {
                        return `/api/v1/${relationship}?search_params=${JSON.stringify(default_fk)}`
                    }*/
                    if (form_element.below) {
                        this.keyListeners = (e, obj) => {
                        }
                        let {always_refresh = false} = form_element
                        return <FormTable key={`${JSON.stringify(default_fk)}${always_refresh ? Math.random() : ''}`} {...params}/>
                    }
                    this.form_table.push(
                        {
                            icon, tab_name, params, component
                            // <FormTable {...this.props} {...form_element} id={id} handleChange={handleChange} value={value} required={required} url={url}/>
                        }
                    )
                }
                return null
            }
        // if (form_state === 'details'){
        //     return <TextField {...params}/>
        // }
        switch (form_element.type) {
            case 'select':
                if (form_state === 'search') {
                    return <MultiSelectField  {...{...params, handleChange: this.multiSelectHandleChange}}/>
                }
                return select_field(params)
            case 'multiselect':
                return <MultiSelectField  {...{...params, handleChange: this.multiSelectHandleChange}}/>
            case 'textarea':
                return <TextArea {...params}/>
            case 'table':
                return makeTable()
            case 'report':
                return makeTable()
            case 'heading':
                return <h4 style={{marginTop: '10px'}}>{form_element.name}</h4>
            case 'calculated':
                return <Calculated {...params} {...record_values} {...this.state} disabled={true}/>
            case 'reference':
                // console.log('Primary keys passed', {primary_key_columns: this.props.primary_key_columns})
                let
                    primary_key_value = build_primary_key_value(),
                    reference = (form_element.reference && element_id(form_element.reference)) || exact_primary_key_column(primary_key_columns, record_values, form),
                    // new_params = {...params, primary_key_value, fullId: form_element.reference_column || fullId, reference},
                    new_params = {...params, primary_key_value, fullId, reference}
                // {state = this.props.record_values} = this
                return <TextField token={token} {...new_params} />
            case 'ymonth':
                let handleYMonth = (ymonth) => {
                    if (['insert', 'update', 'search'].includes(this.props.form_state)) {
                        this.setState({
                            [id]: ymonth
                        })
                    }
                }
                if (form_state === 'search') {
                    return <TextField {...params} num_results={num_results}/>
                }
                return <YMonth {...params} handleChange={handleYMonth}/>
            case 'file':
                return <File {...params} />
            case 'html':
                return <div dangerouslySetInnerHTML={{__html: form_element.name || form_element.defaultValue || form_element.value}}/>
            case 'tab':
                if (!['insert', 'search'].includes(form_state)) {
                    let {fa, label, name, tab_name = 'Tab Name Not Set', icon = icon_generator(fa || 'user', label || tab_name || name), component = function(props){return <div>Component not set</div>}} = form_element
                    this.form_table.push({icon, tab_name, component: component.bind(this), params: {...params, populateForm: this.populateForm}})
                }
                return null
            case 'custom':
                let {component} = form_element,

                    CustomComponent = component.bind(this),
                    // CustomComponent = (function () {
                    //     try {
                    //         return component.bind(this)
                    //     } catch (e) {
                    //         return component
                    //     }
                    // })(),
                    addToFormTable = (form_params) => {
                        let {tab_name = 'Tab Name Not Set', icon = icon_generator('user', tab_name), component: Component = () => <div>Component not set</div>} = form_params
                        this.form_table.push({icon, tab_name, Component: <Component/>})
                    },
                    state = Object.keys(this.state).length ? this.state : {},
                    component_params = {
                        ...params,
                        populateForm: this.populateForm,
                        state,
                        addToFormTable,
                        form_table: this.form_table,
                        self: this,
                        build_default_fk
                    }
                try {
                    return CustomComponent(component_params)
                } catch (e) {
                    return CustomComponent && <CustomComponent {...params}/> /* Does not push component to form table in time */
                }

            case false:
                if (form_element.source || form_element.options) {
                    if (form_state === 'search') {
                        return <MultiSelectField  {...{...params, handleChange: this.multiSelectHandleChange}}/>
                    }
                    return select_field(params)
                }
                return null
            default:
                if (form_element.source || form_element.options) {
                    if (form_state === 'search') {
                        return <MultiSelectField  {...{...params, handleChange: this.multiSelectHandleChange}}/>
                    }
                    return select_field(params)
                }

                // form_element.preprocessor !== undefined && console.log('SHOWING PROPS', {props: this.props.num_results})
                return <TextField {...params} num_results={num_results}/>
        }

    }

    process_line_item = (form_elements, index, line) => {
        log_where && console.log('Page Component Mounted > Modal Component Mounted > Populating Form Elements')
        let {record_index, record_values, form_state} = this.props,
            size = calcSize(line),
            {id, ...form_element} = form_elements,
            {fullId = id} = form_elements,
            newId = id ? element_id(id) : logNoId(form_elements),
            form_value = (() => {
                if ({...this.state, ...this.variables}[newId] !== undefined && form_state !== 'details')
                    return {...this.state, ...this.variables}[newId]
                else
                    return (record_values && record_values[newId])
            })(),
            element_size =
                this.props.size ?
                    12 :
                    (form_element.type === 'table' && line.length === 1) ?
                        '12' :
                        (
                            form_element.col ?
                                form_element.col :
                                form_element.span ?
                                    form_element.span * size :
                                    size
                        ),
            element_type = (Object.keys(form_element).length > 0) && this.createInput(fullId, form_element, newId, form_value)

        if ((form_state && form_state === 'update') && (record_index || record_index === 0)) {
            if (form_elements === {} && !(newId in record_values)) {
                console.log('Producing null NON-EXISTENT ID', {record_values, form_elements})
            }
        }
        if (form_element.table) {
            form_element.type = 'table'
        }
        return (<div key={index} className={`col-sm-${element_size}`}>{element_type}</div>)

    }

    populateForm = (subform) => {
        log_where && console.log('Page Component Mounted > Modal Component Mounted > Populating Form Elements')
        let
            process_line = (line, index) => {
                let horizontal_rule = () =>
                        <div className='col-md-12'>
                            <hr style={{marginTop: 7, marginBottom: 0}}/>
                        </div>,
                    new_line = line.filter(form_element => form_element.showInModal === undefined || form_element.showInModal),
                    line_items = line.length ? new_line.map(this.process_line_item) : horizontal_rule()
                return (<div key={index} className="row">{line_items}</div>)
            },
            populated_form = subform ? subform.lines.map(process_line) : "Loading form elements ..."

        return {populated_form, form_table: this.form_table}
    }

    is_a_report = () => {
        let {target, form} = this.props
        return form.type === 'report' || target.split(" ")[0] === 'REPORT'
    }

    form_params = () => {
        let {target} = this.props
        if (this.is_a_report()) {
            let
                iframe_target = report_target || Math.floor(Math.random() * 20 + 1).toString(),
                props = (() => {
                    if (this.state.output && (['PDFV', 'XLSO', 'WEB'].includes(this.state.output))) {
                        let
                            url_target = this.props.match.url.replace('/page/', '')
                        return {
                            onSubmit: () => {
                                let open_window

                                open_window = !(iframe_target === report_target && url_target === this.props.target);
                                open_window && window.open('about:blank', iframe_target, `width=${window.innerWidth},height=${window.innerHeight},centerscreen=yes,status=yes`).focus()
                                setTimeout(() => {
                                    this.props.hideModal()
                                    // console.log('Frame stuff', window.frames[report_target])
                                    // !open_window && window.frames[report_target].focus()
                                }, 1000)

                            },
                            target: iframe_target
                        }
                    } else {
                        return {
                            target: iframe_target
                        }
                    }
                })()
            return {action: `/reports/generate?target=${target}`, ...props, method: 'POST'}
            // return {action:'/#', onSubmit:this.handleReport}
        } else {
            return {action: '/#', onSubmit: this.handleSubmit}
        }
    }

    form = () => {
        this.form_table = []
        let {form, form_state} = this.props,
            // addImportTab = (form) => {
            //     return {...form, lines: [...form.lines, [ImportTab(form)]]}
            // },
            // form = addImportTab(TopForm),
            [active, setLocalActive] = useState(subTableBelow(this.props.form) ? 0 : -1),
            setActive = val => {
                setLocalActive(val)
                this.active = val
            },
            {populated_form, form_table} = this.populateForm(form),
            changeTab = (e, tab_name) => {
                e.preventDefault()
                this.props.updateDisplayState({form_state: 'details'})
                setActive(tab_name)
            },
            formTableHeaders = (item, index) => {
                return (
                    <li key={index} role="presentation" className={active === index ? 'active' : null}><a href="/#" onClick={(e) => changeTab(e, index)}>{item.icon || item.tab_name || index}</a></li>
                )
            },
            {eager_load_tables = false} = form,
            displayFormTable = (item, index) => {
                let {component: ElementComponent = FormTable} = item,
                    {record_index} = this.props
                return (
                    (index === active || eager_load_tables) && <div key={`${record_index}_${index}`} className={`tab-pane ${active === index && 'active'}`}>
                        <ElementComponent {...item.params}/>
                    </div>
                )
            },
            {modal_body: ModalBody} = this,
            modal_navigation = form_table.length ?
                <ul className="nav nav-tabs" role="tablist">
                    {!subTableBelow(this.props.form) && <li role="presentation" className={active === -1 ? 'active' : null}><a href="/#" onClick={(e) => changeTab(e, -1)}>
                        {form.icon || icon_generator(form.fa || configs.default_icon_fa, form.label || form.tab_name || form.table || 'Main', form.fa_type) || form.tab_name || 'Main'}
                    </a></li>}
                    {form_table.map(formTableHeaders)}
                </ul> : null,
            active_tab_pane_class = `tab-pane ${active === -1 && 'active'}`,
            params = this.form_params(),
            FormButtons = () => (
                (form.readonly || buttons_top) ? null : form_state !== 'details' ? (
                        <div className="row form-group-sm">
                            <div className="col-md-offset-2 col-md-4 submit-row">
                                {
                                    (true || this.is_a_report()) ?
                                        <button type="submit" className="btn btn-primary" style={{width: '100%'}}>Submit</button> :
                                        <a href="/#" type="submit" className="btn btn-primary" style={{width: '100%'}} onClick={this.handleSubmit}>Submit</a>
                                }
                            </div>
                            <div className="col-md-4 submit-row">
                                <a href="/#" className="btn btn-danger" style={{width: '100%'}} role="button" onClick={this.handleCancel}>Cancel</a>
                            </div>
                        </div>
                    ) :
                    (<div className="row form-group-sm">
                        <div className="col-md-4 col-md-offset-4 submit-row">
                            <this.edit_button/>
                        </div>
                    </div>)
            ),
            FormDiv = this.FormDiv({
                    children: <>
                        {populated_form}
                        <FormButtons/>
                    </>,
                    ...params
                }
            ),
            nav_tabs = form_table.length ? form_table.map(displayFormTable) : null,
            View = subTableBelow(this.props.form) ? (
                <ModalBody>
                    <div className={active_tab_pane_class}>
                        {FormDiv}
                    </div>
                    {modal_navigation}
                    <div className="tab-content">
                        {nav_tabs}
                    </div>
                </ModalBody>
            ) : (
                <ModalBody>
                    {modal_navigation}
                    <div className="tab-content">
                        <div className={active_tab_pane_class}>
                            {FormDiv}
                        </div>
                        {nav_tabs}
                    </div>
                </ModalBody>
            )
        useEffect(() => {
            if (['update', 'insert', 'search'].includes(form_state) && (active !== (subTableBelow(this.props.form) ? 0 : -1))) {
                setActive(subTableBelow(this.props.form) ? 0 : -1)
            }
        }, [form_state, active])
        let callback = (
            active === (subTableBelow(this.props.form) ? 0 : -1) ?
                this.keyListeners
                :
                e => this.keyListeners(e, {active, setActive})
        )
        useEffect(() => {
            window.addEventListener('keyup', callback);

            return () => {
                window.removeEventListener('keyup', callback);
            }
        },)
        return (
            form && View
        ) || null
    };

    handleChange = (e, object) => {
        if (['insert', 'update', 'search'].includes(this.props.form_state)) {
            if (e) {
                e.preventDefault && e.preventDefault()
                this.setState({
                    [e.target.id]: e.target.value
                })
            } else {
                this.setState(object)
            }
        }
    }

}


export default FormGenerator

const logNoId = (form_elements) => {
    if (Object.keys(form_elements).length && form_elements.type !== 'heading' && form_elements.type !== 'table') {
    }
    return null
}
