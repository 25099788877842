import React, {useState} from 'react'
import FormSuper from "../form/FormSuper"

export default function (props) {
        let
            // [show_modal, toggleModal] = useState(true),
            // [record_values, setRecordValues] = useState(record),
            index = props.index,
            record = props.record,
            {table_data, search_params, modal_key} = this.state,
            {showAlert, default_fk, form} = this.props,
            // results_per_page = this.props.entries || this.state.results_per_page,
            {target, page_name} = this.props,
            // setIndex = () => null,
            reloadPage = () => null,
            {handleDelete, clear_search} = this,
            {num_results, primary_key: primary_key_column, primary_keys: primary_key_columns, total_pages, page: table_page} = this.state.table_data,
            [form_state, setFormState] = useState('details'),
            // updateDisplayStateAndRefresh = (params) => {
            //     Object.keys(params).includes('form_state') && setFormState(params.form_state)
            //     Object.keys(params).includes('show_modal') && toggleModal(params.show_modal)
            //     Object.keys(params).includes('search_params') && this.updateDisplayStateAndRefresh(params)
            // },
            // hideModal = () => {
            //     toggleModal(!show_modal)
            //     setRecordValues(record)
            //     setFormState('details')
            // },
            updateDisplayState = (args = {}) => {
                if (args.form_state === 'update' && args.show_modal === true && Object.keys(args).length === 2) {
                    setFormState(args.form_state);
                } else if (args.form_state && Object.keys(args).length === 1) {
                    setFormState(args.form_state);
                } else {
                    this.updateDisplayState(args)
                }
                // (args.record_values === null) && setRecordValues(null)
            },
            updateDisplayStateAndRefresh = (args) => {
                updateDisplayState(args)
                this.setState({record_values: undefined})
                setFormState('details')
                this.updateDisplayStateAndRefresh(args)
            },
            // refreshTable = () => {
            //     setFormState(null)
            //     toggleModal(false)
            //     // setRecordValues(null)
            //     this.refreshTable()
            // },
            params = {
                form_state, hideModal: () => {
                    if (this.props.history.location.pathname.includes(this.props.target)) {
                        this.props.history.replace({pathname: this.props.history.location.pathname, search: `?page=${this.state.page}`})
                    }
                    this.updateDisplayStateAndRefresh({search_params: null, show_modal: false, record_values: undefined, record_index: undefined})
                },
                num_results, page_name, primary_key_column, primary_key_columns, record_index: index, record_values: record,
                refreshTable: this.refreshTable, reloadPage, setIndex: this.setIndex, showAlert, table_page, target, show_modal: true, updateDisplayState, updateDisplayStateAndRefresh,
                total_pages, clear_search,
                show_header: true, default_fk, search_params, height: this.state.height - 160, handleDelete, form
            }
        return (
            <FormSuper key={modal_key} {...params}/>
        )
    }