import foreign_keys from "../foreign_keys";

const fields = [
    {name: 'Vehicle Number', id: 'insurance.chassis_no', type: 'select', span: 1, source: 'vehicles.civilian_plate_no', required: true, showInTable: true},
]

export default {
    type: 'table',
    name: 'PROGRAM insurance',
    tab_name: 'Insurance Policy(ies)',
    table: 'insurance',
    fa:'scroll',
    label: 'Insurance',
    lines: [
        [
            {name: 'Policy Number', id: 'insurance.policy_number', type: 'text', span: 1, required: true},
            fields[0],
            {...foreign_keys.create_reference(fields[0]), name: 'Civilian Plate No', showInModal: false}
        ], [
            {name: 'Issuer', id: 'insurance.issuer', type: 'text', span: 2, required: true},
        ], [
            {name: 'Expiration Date', id: 'insurance.expiration_date', type: 'date', span: 1, required: true},
            {name: 'Issued Date', id: 'insurance.issue_date', type: 'date', span: 1, required: true},
        ], [
            {name: 'Remarks', id: 'insurance.remarks', type: 'textarea', span: 3}
        ],
    ]
}