import roles_users from "./roles_users";
import foreign_keys from "../foreign_keys";

let division = {name: 'Division', id: 'User.division', /*type: 'select', source: 'User.division', options: {value: 'division'}, creatable: true,*/ span: 1},
    department = {name: 'Department', id: 'User.department', /*type: 'select', source: 'User.department', options: {value: 'department'}, creatable: true,*/ span: 1}

export default {
    type: 'table',
    name: 'PROGRAM user',
    tab_name: 'User(s)',
    post_url: '',
    table: 'User',
    lines: [
        [
            {name: 'First Name', id: 'User.first_name', type: 'text', required: true},
            {name: 'Last Name', id: 'User.last_name', type: 'text', required: true}
        ], [
            {name: 'PF. No.', id: 'User.email', type: 'text', required: true},
            {name: 'Is Active', id: 'User.active', type: 'select', ...foreign_keys.yes_no_boolean, span: 1}
        ], [
            division,
            department
        ],
        [{name: 'Password', id: 'User.password', type: 'password', showInTable: false, required: true}, {name: 'Confirm Password', id: 'User.confirm_password', type: 'password', showInTable: false},],
        [{...roles_users, fkey: 'user_id'}]
    ]
}
