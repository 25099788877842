import React, {PureComponent} from 'react'
import API from '../../store/api'
import {Form} from './dummyForm'
import {withRouter} from 'react-router-dom'
import {server_error} from "./errors";

const Form2 = withRouter(Form)


export class Development extends PureComponent {
    state = {
        page_items : []
    }

    componentDidMount() {
        this.getForm()
    }

    getForm = () => {
        API.post('/hr/page', {target: this.props.target})
            .then(response => {
                this.setState({
                    page_items: response.data.data
                })
            })
            .catch(error => {
                console.log(server_error(error))
            })
    }

    render() {
        const skeleton = this.state.page_items ? this.state.page_items.map((object, index) => <pre key={index}>{object.value}</pre>) : null;
        return (
            <div className="row">
                <div className="col-md-6">
                    <Form2 {...this.props.modal_properties} showModal={this.props.show} target={this.props.target} hideModal={()=>null} updateDisplayStateAndRefresh={()=>null}/>
                </div>
                <div className="col-md-6">
                    {skeleton}
                </div>
            </div>
        )
    }
}

export default Development