import API from '../api'


export const
    signIn = (credentials) => {
        return (dispatch, getState) => {
            API.post('/api/v1/auth', credentials)
                .then((response) => {
                    dispatch({type: 'LOGIN_SUCCESS', data: response.data})
                })
                .catch((error) => {
                    console.log('Login error details', {error})
                    if (error.response && error.response.data){
                        dispatch({type: 'LOGIN_ERROR', data: error.response.data})
                    }else{
                        alert('Server not responding')
                        console.log(error)
                    }
                    // dispatch({type: 'LOGIN_ERROR', data: error.response.data.description})
                })
        }
    },
    localSignIn = data => {
        return (dispatch, getState) => {
            dispatch({type: 'LOGIN_SUCCESS', data: data})
        }
    },
    signOut = () => {
        return (dispatch, getState) => {
            // localStorage.clear();
            API.get('/api/v1/logout')
                .then(() => {
                    console.log('SERVER SIGNED OUT')
                    dispatch({type: 'SIGNOUT_SUCCESS'})
                })
            dispatch({type: 'SIGNOUT_SUCCESS'})
        }
    };

// export const signUp = (newUser) => {
//     return (dispatch, getState) => {
//         API.post('/api/v1/auth/register', {email: newUser.email, password: newUser.password})
//         .then((response) => {
//             console.log('Registered the user in database records')
//         })
//         .catch(err=>{
//             dispatch({type: 'SIGNUP_ERROR', err})
//         })
//     }
// };