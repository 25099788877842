import React from 'react';
import Mainbody from './components/navigation'

function App() {
  return (
    <div className="App">
     <Mainbody/>
    </div>
    
  );
}

export default App;
