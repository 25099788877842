import React from 'react'
import {connect} from 'react-redux'
import {setTarget} from '../../store/actions/pageactions'
import {ModalAlert} from './modal_alert'
import Modal from './form/modal'
import Style from "../StyledBody";
import Development from './development'
import {problem_form} from "./form/forms.global";
import PageSuper from "./parent";
import {getForm} from "./table/utilities"
import configs, {icon_large} from "../../custom/configs";

class RoutePage extends PageSuper {
    componentDidMount() {
        this.scrollToTop && window.scrollTo(0, 0)
        this.setState(state => ({
            target: this.props.history.location.pathname.replace('/page/', ''),
        }))
        let target = this.props.history.location.pathname.replace('/page/', ''),
            title_found = this.props.target_title.length && this.props.target_title.find(item => item.target === target),
            title = title_found ? title_found.title : '',
            page_name = title.split('>').pop()
        document.title = `${icon_large} | ${page_name}`
    }


    ProblemModal = ({title, target}) => {
        const
            {showAlert, updateDisplayState} = this,
            {modal_properties, show_problem} = this.state,
            params = {
                ...modal_properties, showAlert, target, updateDisplayStateAndRefresh: () => null, show_modal: show_problem, form: problem_form(title),
                updateDisplayState: ({show_modal:show_problem}) => {
                    updateDisplayState({show_problem})
                },
                hideModal: () => this.setState({show_problem: false}), form_state: 'insert', form_header: 'Report a problem'
            }
        return <Modal {...params} />
    }


    render() {
        const
            {showAlert} = this,
            {show_alert, show_modal, modal_properties, alertMessage: message, alert_status, show_problem, production} = this.state,
            target = this.props.history.location.pathname.replace('/page/', ''),
            title_found = this.props.target_title.length && this.props.target_title.find(item => item.target === target),
            title = title_found ? title_found.title : '',
            page_name = title.split('>').pop(),
            {form = getForm(target), isAuthenticated} = this.props,
            {table_key, table_properties, show_index, table_actions} = this.state,
            {export_action, refreshTable} = this,
            params = {
                ...table_properties, showAlert, target, export_action, show_index, refreshTable, table_actions, page_name, form
            }
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h4> {title.replace(page_name, '')} <u>{page_name}</u> <small>[{target}]</small></h4>
                    <ol className="breadcrumb">
                        {/*<li><a href="/#"><i className="fa fa-dashboard"/> Home</a></li>*/}
                        {/*<li className="active">Dashboard</li>*/}
                        {/*<li>{title.replace(page_name, '')}</li>*/}
                        {/*<li className="active">{page_name}</li>*/}
                        <li>
                            {/*{configs.showDevelopmentButton && <button className={`btn btn-${production ? 'success' : 'warning'} btn-xs`} onClick={() => this.setState({production: !production})}>{production ? 'Development' : 'Production'} Mode</button>}*/}
                            <button className="btn btn-danger btn-xs" onClick={() => this.setState({show_problem: true})}>Help?</button>
                        </li>
                    </ol>
                </section>
                <section className="">
                    <div className="row">
                        <div className="col-md-12">
                            <this.TableSubComponent key={table_key} {...params}/>
                        </div>
                    </div>
                    {/*{!production && target && <Development target={target} modal_properties={modal_properties} show={show_modal}/>}*/}
                </section>
                {show_alert && <ModalAlert showModal={showAlert} {...{message, alert_status, showAlert}} />}
                {show_problem && <this.ProblemModal title={title} target='program problem'/>}
                <this.Exporter/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.pageReducer,
        ...state.authReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTarget: (target) => dispatch(setTarget(target)),
    }
};

export default Style(connect(mapStateToProps, mapDispatchToProps)(RoutePage))

/*
Todo
- Connect the database vm to the application vm
- Each page should show table of list of data
- On Clicking of each list item, it should show the details of the item
- Create backend dictionary that returns the emplementation of a function which contains json data
- Research on loading JSX from server then inserting data into the JSX
*/
/*
* // const target = this.props.match.params.target
// console.log(target === this.state.target)
// if (target !== this.state.target){
//     API.post('/hr/page', {target:target})
//     .then(response =>{
//         this.setState({
//             data: response.data.data,
//             target: this.props.match.params.target
//         })
//     })
//     .catch(error => {
//         console.log(error.response)
//         console.log(typeof(error.response))
//         this.setState({
//             data: "********* Error occurred *********",
//             target: this.props.match.params.target
//         })
//     })
// }
* */