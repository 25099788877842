import React, {PureComponent} from 'react'
import Form from './form'
import API from '../../store/api'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import {signIn, localSignIn} from '../../store/actions/authactions'
import configs, {login_title, login_title_small} from '../../custom/configs'
import {withRouter} from 'react-router-dom'
// import {Link} from "react-router-dom";

let search_param = '?next=',
    redirect_page = (location) => {
        if (location.search && location.search.includes(search_param)) {
            return location.search.replace(search_param, '')
        }
        return configs.default_page || '/dashboard'
    }

class Index extends PureComponent {
    state = {
        authenticated: false
    }

    componentDidMount() {
        this.isAuthenticated()
    }

    isAuthenticated = () => {
        let
            {location, authReducer, redirect = true} = this.props
        if (authReducer.isAuthenticated) {
            API.get('/api/v1/auth')
                .then(response => {
                        let {authenticated} = response.data
                        // this.setState({authenticated})
                        if (authenticated) {
                            this.props.localSignIn(response.data)
                            redirect && this.props.history.push(redirect_page(location))
                        }
                    },
                    error => {
                        console.log('There has been an error in confirming authentication', error)
                    }
                )
        }
    }

    render() {
        let
            {location, authReducer, redirect = true} = this.props
        return (authReducer.isAuthenticated && redirect) ? <Redirect to={redirect_page(location)}/> : (
            <div>
                <div className="wrapper">
                    <center>
                        <section className="content" style={{color: "white", marginTop: "200px"}}>
                            <div className="col-sm-12">
                                <h1>{login_title}</h1>
                                <p className="lead">{login_title_small}</p>
                            </div>
                        </section>
                    </center>
                </div>

                <div>
                    <center>
                        <section className="content">
                            <div className="col-sm-12">
                                <Form/>
                            </div>
                        </section>
                    </center>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        token: state.authReducer.token || null,
        authReducer: state.authReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials) => dispatch(signIn(credentials)),
        localSignIn: (data) => dispatch(localSignIn(data)),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))