import React, {Component} from 'react'
import gprs_locations from "./gprs_locations";
import {connect} from "react-redux";
import MapsOSM, {OSMMap, mapStateToProps, mapDispatchToProps} from "./maps/MapsOSM";
import {Popup} from "react-leaflet";
import Box from "../../../components/page_route/box";

class Comp extends Component {
    componentDidMount() {
        this.minimizeSide()
    }

    minimizeSide = function () {
        let {default_fk} = this.props
        if (!default_fk) {
            let body = document.body;
            body.classList.add("sidebar-collapse");
            document.getElementsByClassName('content-header')[0].style.display = 'none';
            this.forceUpdate()
        }
    }

    render() {
        let {objects} = this.props.table_data,
            {default_fk} = this.props,
            {zoom = 10} = this.props.form,
            handleChange = e => {
                e.preventDefault()
            }
        if (objects) {
            let form_values = null/*,
                createInfo = object => {
                    let {plate, lat, lon, battery_level, speed, altitude, course, imei, timestamp, full_name, rfid} = object
                    return lat && lon && (
                        <Popup>
                            Driver: {full_name}<br/>
                            RFID: {rfid}<br/>
                            Plate: {plate}<br/>
                            IMEI: {imei || 'not set'}<br/>
                            Timestamp: {timestamp || 'not set'}<br/>
                            Lat: {lat}<br/>
                            Lon: {lon}<br/>
                            Battery: {battery_level}<br/>
                            Speed: {speed}<br/>
                            Altitude: {altitude}<br/>
                            Course: {course}<br/>
                        </Popup>
                    )
                }*/
            return <MapsOSM form_values={form_values} points={objects} zoom={zoom} style={{height: window.innerHeight - 100 - (default_fk ? 110 : 0)}} handleChange={handleChange}/>
        } else {
            return null
        }
    }
}

const preprocessor = function (state, props) {
    if (props && props.form_values && props.form_values.latitude && props.form_values.longitude && !isNaN(props.form_values.latitude) && !isNaN(props.form_values.longitude)) {
        return {
            lat: props.form_values.latitude,
            lng: props.form_values.longitude
        }
    }
}

const OSMMap_modal = function (props) {
    let ThisComponent = connect(mapStateToProps, mapDispatchToProps)(class extends OSMMap {
        updateForm = (lat_long) => {
            this.props.handleChange({
                preventDefault: () => null,
                target: {
                    id: 'lat',
                    value: lat_long.lat
                }
            })
            this.props.handleChange({
                preventDefault: () => null,
                target: {
                    id: 'lon',
                    value: lat_long.lng
                }
            })

        }
    })
    return <ThisComponent {...props}/>
}

export default {
    name: 'PROGRAM gprs locations',
    tab_name: 'GPRS Tracker Locations',
    type: 'table',
    table: 'gprs_locations_received',
    fa: 'map-marker',
    label: 'Locations',
    pkey: 'civilian_plate_no',
    fkey: 'plate',
    readonly: true,
    title: ['rfid', 'civilian_plate_no', 'full_name'],
    // readonly: true,
    lines: [
        [
            {name: 'Map', id: 'tracker_map', type: 'custom', span: 3, component: OSMMap_modal, showInTable: false},
        ], [
            {name: 'Location ID', id: 'gprs_locations.location_id', type: 'text', disabled: true, showInTable: true},
            {name: 'Civ. Plate', id: 'gprs_locations.civilian_plate_no', type: 'text'},
            {name: 'GOK Plate', id: 'gprs_locations.gok_plate_no', type: 'text'},
            {name: 'Timestamp', id: 'gprs_locations.timestamp', type: 'text'},
        ], [
            {name: 'Vehicle', id: 'makemodel', type: 'text'},
            {name: 'Employee No', id: 'employment_no', type: 'text', showInTable: false},
            {name: 'Driver', id: 'full_name', type: 'text'},
            {name: 'Phone number', id: 'gprs_locations.phone_number', type: 'text'},
        ], [
            {name: 'RFID', id: 'gprs_locations.rfid', type: 'text'},
            {name: 'IMEI', id: 'gprs_locations.imei', type: 'text'},
            {name: 'Latitude', id: 'gprs_locations.lat', type: 'text'},
            {name: 'Longitude', id: 'gprs_locations.lon', type: 'text'},
        ], [
            {name: 'Altitude', id: 'gprs_locations.altitude', type: 'text'},
            {name: 'Course', id: 'gprs_locations.course', type: 'text'},
            {name: 'Speed', id: 'gprs_locations.speed', type: 'text'},
        ], [
            {name: 'Satellite', id: 'gprs_locations.num_satellite', type: 'text'},
            {name: 'Source IP', id: 'gprs_locations.remote_addr', type: 'text'},
            {name: 'Battery Level', id: 'gprs_locations.c', type: 'text'},
        ], [
            {name: 'Tracker Data', id: 'gprs_locations.data', type: 'textarea', span: 3},
            // ], [
            //     {name: 'Map', id: 'tracker_map', type: 'custom', span:3, component: OSMMap, preprocessor, showInTable: false},
        ],
    ],
    beforeTable: Comp,
    zoom: 9,
}


/*
* Todo
*   trigger to send notification if vehicles insurance number is due for renewal*/