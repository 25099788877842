import React from 'react'
import ReportIframe from "../../../components/page_route/report_iframe";
import drivers from './drivers'
import Box from "../../../components/page_route/box";

export const RFID = props => {
    let {showAlert=()=>null, updateDisplayState=()=>null} = props
    return (
        <div className='row'>
            <Box title='RFID Reader / Writer'>
                <ReportIframe src='http://localhost:1880/ui' style={{height: 800}} showAlert={showAlert} name='rfid_read_write' updateDisplayState={updateDisplayState}/>
            </Box>
        </div>
    )
}
export default {
    ...drivers,
    name: 'rfid',
    tab_name: 'Driver',
    type: 'table',
    table: 'drivers',
    beforeTable: RFID,
    readonly: true,
}