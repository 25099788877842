import vehicle_service_receipts from "./vehicle_service_receipts";
import vehicle_service_repair from "./vehicle_service_repair";

const
    levels_of_service = [
        {value: 'General Service'},
        {value: 'Annual Service'},
        {value: 'Repair'},
        // {value: 'Close to Service'},
        // {value: 'Due for Service'},
        // {value: 'Past Service Date'},
    ]

export default {
    name: 'PROGRAM service',
    tab_name: 'Service / Repair',
    type: 'table',
    table: 'service',
    fa: 'wrench',
    label: 'Service',
    lines: [
        [
            {name: 'Service No.', id: 'service.service_id', type: 'number', disabled: true, span: 1},
            {name: 'Vehicle Id', id: 'service.chassis_no', type: 'select', source: 'vehicles.civilian_plate_no', span: 1},
        ],
        [
            {name: 'Date of Service', id: 'service.service_date', type: 'date', span: 1},
            {name: 'Due date of Next Service', id: 'service.next_service_date', type: 'date', span: 1},
        ], [
            {name: 'Service Company', id: 'service.service_company', type: 'text', span: 1},
            {name: 'Level of Service', id: 'service.service_level', type: 'select', options: levels_of_service, span: 1},
        ], [
            {name: 'Type of Service', id: 'service.service_type', type: 'select', options: [{value: 'Service'}, {value: 'Repair'}], span: 1},
            {name: 'Total Cost of Service', id: 'service.service_cost', type: 'number', span: 1},
        ], [
            {name: 'Remarks', id: 'service.remarks', type: 'textarea', span: 3},
        ],
        [vehicle_service_repair],
        [vehicle_service_receipts],
    ]
}

/*
* Todo
*  Make level of service a calculated value*/