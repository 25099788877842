import {problem_form} from "../../components/page_route/form/forms.global";
import vehicles from "./modules/vehicles";
import drivers from "./modules/drivers";
import driver_vehicle, {returned} from "./modules/driver_vehicle";
import vehicle_service from "./modules/vehicle_service";
import trips from "./modules/trips";
import vehicle_service_receipts from "./modules/vehicle_service_receipts";
import vehicle_service_repair from "./modules/vehicle_service_repair";
import vehicle_model from "./modules/vehicle_model";
import vehicle_make from "./modules/vehicle_make";
import tracker from "./modules/tracker";
import tracker_location from "./modules/tracker_location";
import insurance from "./modules/insurance";
import users from "./users/users";
import roles_users from "./users/roles_users";
import roles from "./users/roles";
import gprs_locations from "./modules/gprs_locations";
import gprs_locations_recent from "./modules/gprs_locations_recent";
import rfid from "./modules/rfid";
import tracker_logs from './tracker_logs'

export const FormProperties = [
    problem_form(null), vehicles, drivers, driver_vehicle, vehicle_service, trips, vehicle_service_receipts,
    vehicle_service_repair, vehicle_model, vehicle_make, tracker, tracker_location, insurance, users, roles, roles_users, gprs_locations,
    gprs_locations_recent, returned, rfid, tracker_logs
]
