import foreign_keys from "../foreign_keys";

export default {
    name: 'vehicles_assigned',
    tab_name: 'Driver Vehicle Assignment',
    type: 'table',
    table: 'driver_vehicles',
    lines: [
        [
            {name: 'Driver', id: 'driver_vehicle.driver_id', type: 'select', source: 'drivers.full_name', options: {value: '', name: 'full_name'}, span: 1},
            {name: 'Vehicle', id: 'driver_vehicle.chassis_no', type: 'select', source: 'vehicles.civilian_plate_no', options: {value: '', name: ''}, span: 1},
        ], [], [
            {name: 'Date Assigned', id: 'driver_vehicle.date_assigned', type: 'date', span: 1},
            {name: 'Mileage on Assignment', id: 'driver_vehicle.mileage_assigned', type: 'number', span: 1},
            {name: 'Assigned By', id: 'driver_vehicle.assigned_by', type: 'text', span: 1}
        ], [
            {name: 'Condition on Assignment', id: 'driver_vehicle.condition_assigned', type: 'textarea', span: 3}
        ], [
            {name: 'Due Return', id: 'driver_vehicle.date_due', type: 'date', span: 1},
        ], [
            {name: 'Date Returned', id: 'driver_vehicle.date_returned', type: 'date', span: 1},
            {name: 'Mileage on Return', id: 'driver_vehicle.mileage_returned', type: 'number', span: 1},
            {name: 'Received By', id: 'driver_vehicle.received_by', type: 'text', span: 1},
        ], [
            {name: 'Condition on Return', id: 'driver_vehicle.condition_returned', type: 'textarea', span: 3}
        ], [
            {name: 'Is Vehicle Usable?', id: 'driver_vehicle.usable', type: 'select', ...foreign_keys.yes_no, span: 1.5}
        ]

    ]
}

/*
* Todo
*   Service / Repair History
*   Accident History
*   Service History*/