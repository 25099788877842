// https://dev.to/achowba/building-a-modal-in-react-15hg
import React, {PureComponent} from 'react';
import TextField from './form/formelements/textfield'
import SelectField from './form/formelements/select'
import TextArea from './form/formelements/textarea'
import {global_token, database_table} from '../../store/actions/pageactions'
import {element_id, getForm, calcSize} from './table/utilities'
import API from '../../store/api'
import {server_error} from "./errors";

// import API from
const log_where = false

export class Form extends PureComponent {
    handleSubmit = (e) => {
        e.preventDefault()
        console.log("Submission has happened")
        const {record_index, record_values, form_state, primary_key_column, token} = this.props
        const action = this.props.form_state || (record_index || record_index === 0 ? 'update' : 'create') // states create, update, search

        if (action === 'insert') {
            this.api_post_data({data: this.state, token})
        } else if (action === 'update') {
            const primary_key = record_values[primary_key_column]
            console.log('Primary key', primary_key)
            this.api_put_data({primary_key, data: this.state, form_state, token})
        } else if (action === 'search') {

        }
    }


    api_post_data = ({...args}) => {
        // Todo Remove global token
        let {table, token, data} = args
        table = table || database_table(this.props.target)
        token = token || this.props.token || global_token
        let url = `/api/v1/${table}`

        API.defaults.headers.common['Authorization'] = `JWT ${token}`;
        API
            .post(url, data)
            .then((response => {
                        console.log('Page Actions Posted', response.data)
                        this.props.updateDisplayStateAndRefresh({table_page: this.props.table_page || 1})
                        this.props.hideModal()
                        // this.refreshModal()
                    }
                ), error => {
                    let message = server_error(error)
                    console.log(message)
                    alert(message)
                }
            )
            .catch(error => {
                console.log('CAUGHT ERROR')
                console.log(error)
                console.log(error.response.status)
                alert(error.response.data)
            })
    }

    api_put_data = ({...args}) => {
        let {primary_key, data, token, target, table} = args

        table = table || database_table(this.props.target)
        token = token || this.props.token || global_token

        const url = `/api/v1/${table || database_table(target)}/${primary_key}`

        if (url && table && data && primary_key) {
            API.defaults.headers.common['Authorization'] = `JWT ${token}`;
            API.put(url, data)
                .then((response => {
                            this.props.updateDisplayStateAndRefresh({table_page: this.props.table_page || 1})
                            this.props.hideModal()
                            // this.refreshModal()
                        }
                    ), error => {
                        console.log('Inner error handler')
                        let message = server_error(error)
                        console.log(message)
                        alert(message)
                    }
                )
                .catch(error => {
                    console.log('Outer error handler')
                    console.log('CAUGHT ERROR')
                    console.log(error)
                    console.log(error.response.status)
                    alert(JSON.stringify(error.response.data))
                })
        } else {
            console.log(table || "The table has not been defined")
            console.log(primary_key || "INSERTING, Primary key has not been defined")
        }

    }

    handleChange = (e) => {
        console.log("Change has happened")
        if (e) {
            this.setState({
                [e.target.id]: e.target.value
            })
        }
    }

    PopulateForm = () => {
        log_where && console.log('Page Component Mounted > Modal Component Mounted > Populating Form Elements', this.props.record_index)
        const form = getForm(this.props.target)
        const is_a_report = (this.props.match.params.target.split(" ")[0] === 'REPORT')
        return form ? form.lines.map((line, index) => {
            return (
                <div key={index} className="row">
                    {
                        line.length ? line.map((form_elements, index) => {

                                let {id, ...form_element} = form_elements
                                id = form_elements.id ? element_id(form_elements.id) : Object.keys(form_elements).length ? console.log('Form element ', form_elements, 'has no id') : null
                                const size = calcSize(line)
                                const {record_index, record_values, form_state} = this.props
                                const editing_of_record = (form_state && form_state === 'update') && (record_index || record_index === 0)
                                const form_value = editing_of_record && form_state === 'update' ? record_values[id] : ''
                                return (
                                    <div key={index}
                                         className={`col-md-${is_a_report || (form_element.type === 'table' && line.length === 1) ? '12' : (form_element.col ? form_element.col : form_element.source && !form_element.span ? 8 : form_element.span ? form_element.span * size : size)}`}>
                                        {
                                            form_element.source && form_element.type !== 'reference' ? <SelectField id={id} key={index} {...form_element} handleChange={(e) => this.handleChange(e)} value={form_value || ''}/> :
                                                (() => {
                                                    switch (form_element.type) {
                                                        case 'select':
                                                            return <SelectField  {...form_element} id={id} handleChange={(e) => this.handleChange(e)} value={form_value || ''}/>
                                                        case 'textarea':
                                                            return <TextArea {...form_element} id={id} handleChange={(e) => this.handleChange(e)} value={form_value || ''}/>
                                                        case 'table':
                                                            return null
                                                            // return <FormTable {...form_element} id={id} handleChange={(e) => this.handleChange(e)} value={form_value || ''}/>
                                                        case 'heading':
                                                            return <h4 style={{marginTop: '10px'}}>{form_element.name}</h4>
                                                        case false:
                                                            return null
                                                        default:
                                                            return <TextField key={index} {...form_element} id={id} handleChange={(e) => this.handleChange(e)} value={form_value || ''}/>
                                                    }
                                                })()
                                        }
                                    </div>
                                )
                            }) :
                            <div className='col-md-12'>
                                <hr style={{marginTop: 7, marginBottom: 0}}/>
                            </div>
                    }
                </div>)

        }) : " Loading form elements ..."

    }

    render() {
        return this.PopulateForm()
    }
}