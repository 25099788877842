
import tracker_location from "./tracker_location";

export default {
    name: 'PROGRAM tracker',
    tab_name: 'Trackers',
    type: 'table',
    fa: 'microchip',
    table: 'trackers',
    lines: [
        [
            {name: 'Tracker Serial', id: 'trackers.tracker_serial', type: 'text', span: 1, required: true},
            {name: 'Vehicle', id: 'trackers.chassis_no', type: 'select', source: 'vehicles.civilian_plate_no', span: 1, required: true}
        ], [
            {name: 'Condition', id: 'trackers.condition', type: 'select', source: '', options: [{value: 'Good'}, {value: 'Bad'}], span: 1},
            {name: 'Date Assigned', id: 'trackers.date_assigned', type: 'date', span: 1},
        ],
        [tracker_location]
    ]
}
/*
* Todo
*   trigger to send notification if vehicles insurance number is due for renewal*/