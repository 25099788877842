import React, {useEffect} from 'react'
import {ReportIframe} from "../../../components/page_route/report_iframe";

export default function () {
    let [state, setLocalState] = React.useState({}),
        updateDisplayState = function (obj) {
            let new_state = {...state},
                {report_title} = obj
            if (report_title) {
                setLocalState({...new_state, report_title})
            }
        },
        handlePrint = (e) => {
            e && e.preventDefault()
            try {
                window.frames['tracker_logs_report'].focus()
                window.frames['tracker_logs_report'].print()
            } catch (e) {
                try {
                    window.print()
                } catch (e) {
                    console.log('Error on printing', e, Object.keys(e))
                    // this.props.showAlert(true, e.toString())
                }
            }
            // eslint-disable-next-line no-unused-expressions
            // window.frames[report_target].preview
        },
        timeout = setInterval(() => {
            let frame = window.frames['tracker_logs_report'];
            if (frame && frame.document.readyState !== 'complete') {
                console.log('Frame found')
                frame.scrollTo(0, 999999)
            } else {
                console.log('Frame not found')

            }
        }, 500),
        onLoadEvent = function (e) {
            clearInterval(timeout)
        },
        params = {style: {height: window.innerHeight - 165}, src: `/tracker/live`, showAlert: _ => null, signOut: _ => null, name: 'tracker_logs_report', updateDisplayState}


    useEffect(function () {
        console.log('Timeout mounted')

        return function () {
            clearInterval(timeout)
        }
    })


    return (
        <>
            <div className={`box box-primary form-box`}>
                <div className="box-header with-border">
                    <div className="box-body">
                        <div className='row'>
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                                <h3 style={{textAlign: 'center', fontWeight: 1000}}>{state.report_title}</h3>
                            </div>

                            <div className="col-md-1 pull-right" style={{minWidth: 100}}>
                                <button className="btn btn-primary btn-warning" title="Print Report" onClick={handlePrint}><span className="fa fa-file-o"/> Print Report</button>
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                        <ReportIframe key={state.year} {...params} onLoadEvent={onLoadEvent}/>
                    </div>
                </div>
            </div>
        </>
    )

}