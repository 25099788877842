import React, {PureComponent} from 'react'
import SelectField from "./select";
// import {TextFieldSuper} from "./textfield";
import fk from "../../../../custom/forms/foreign_keys";
import Select from './select';
import element_style from "./element_style";

class YMonth extends PureComponent {
    state = {
        month: '01',
        year: new Date().getFullYear()
    }

    // componentDidMount() {
    //     let {value} = this.props,
    //         [year, month] = value ? value.split('-') : [null, null]
    //     if (value) {
    //         this.setState({year, month})
    //     }
    // }

    // onChange = (e) => {
    //     e.preventDefault()
    //     this.setState(
    //         {[e.target.id]: e.target.value},
    //         () => {
    //             let {month, year} = this.state,
    //                 year_month = `${year}-${month}`
    //             if (month && year) {
    //                 this.props.handleChange(year_month)
    //             }
    //         }
    //     )
    //
    // }
    //
    // InputField = () => {
    //     let {value, required, id, name, creatable, start, stop} = this.props,
    //         {onChange} = this,
    //         [year, month] = value ? value.split('-') : [null, null]
    //     return (
    //         <div className="row">
    //             <div className="col-xs-6" style={{paddingRight: 0}}>
    //                 <select className="form-control" id='month' onChange={onChange} value={month} required={required} style={{borderColor: (required && (value === undefined || value === null)) ? 'red !important' : undefined, paddingTop: 7}}>
    //                     <option disabled="disabled" value="disabled">--Select Option--</option>
    //                     {
    //                         fk.month_num.options.map((month, index) => {
    //                             return <option key={index} value={month.value}>{month.name}</option>
    //                         })
    //                     }
    //                 </select>
    //             </div>
    //             <div className="col-xs-6" style={{paddingLeft: 0}}>
    //                 {creatable ? <input type="text" className='form-control' id="year" onChange={onChange} value={year} required={required} style={{borderColor: (required && (value === undefined || value === null)) ? 'red !important' : undefined}}/> :
    //                     <select className="form-control" id='year' onChange={onChange} value={year} required={required} style={{borderColor: (required && (value === undefined || value === null)) ? 'red !important' : undefined, paddingTop: 7}}>
    //                         <option disabled="disabled" value="disabled">--Select Option--</option>
    //                         {
    //                             fk.year(start, stop).options.map((year, index) => {
    //                                 return <option key={index}>{year.value}</option>
    //                             })
    //                         }
    //                     </select>}
    //                 <input type="text" defaultValue={value || undefined} name={id} style={{display: 'none'}} readOnly={true}/>
    //             </div>
    //         </div>
    //     )
    // }
    handleObject = (obj) => {
        // alert(JSON.stringify(obj))
        if (!Object.values(obj)[0]) {
            this.props.handleChange(null)
        } else {
            let
                {value} = this.props,
                [val_year, val_month] = value ? value.split('-') : [null, null],
                {month = val_month || '01', year = val_year || new Date().getFullYear()} = obj,
                // {month, year} = this.state,
                year_month = `${year}-${month || '01'}`
            if (month && year) {
                this.props.handleChange(year_month)
            }
        }

    }

    InputField2 = () => {
        let {value, creatable, start, stop} = this.props,
            {handleObject} = this,
            [year, month] = value ? value.split('-') : [null, null]
        return (
            <div className="row">
                <div className="col-md-6">
                    <Select value={month} id='month' options={fk.month_num.options} handleChange={handleObject} isRequired={true} hideInput={true} show_label={false}/>
                </div>
                <div className="col-md-6">
                    <Select value={year} id='year' options={fk.year(start, stop).options} handleChange={handleObject} isRequired={true} hideInput={true} show_label={false} creatable={creatable}/>
                </div>
            </div>
        )
    }

    DetailField = () => {
        let {value} = this.props,
            [year, month] = value ? value.split('-') : [null, null],
            month_name  = value ? fk.month_num.options.find(item => item.value.trim() === month) : {}

        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="form-control">
                        {value ? (`${month_name ? month_name.name : month}-${year}`) : 'N/A'}
                    </div>
                </div>
            </div>
        )
    }


    render() {
        let {id, value, form_state} = this.props
        return (
            <>
                <div className="form-group">
                    {form_state !== 'details' && <this.InputField2/>}
                    {form_state === 'details' && <this.DetailField/>}
                </div>
                <input type="hidden" name={id} value={value || undefined} style={{display: 'none'}}/>
            </>
        )
    }
}

export default element_style(YMonth)