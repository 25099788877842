import React, {Component} from 'react'
import gprs_locations from "./gprs_locations";

export default {
    ...gprs_locations, table: 'gprs_locations_recent', name: 'recent_locations',
    refreshEvery: 15000
}


/*
* Todo
*   trigger to send notification if vehicles insurance number is due for renewal*/
