import {element_id} from '../table/utilities'
import {api_prefix} from "../../../store/api";

export const
    build_primary_key_value = (primary_key_columns = [], record_values) => {
        // let {primary_key_columns = [], record_values} = this.props
        return primary_key_columns.length && record_values && record_values[primary_key_columns[0]]
    },
    build_search_params = (primary_key_columns, record_values) => {
        // let {primary_key_columns, record_values} = this.props
        if (primary_key_columns.length > 1) {
            let search_params = {}
            primary_key_columns.forEach(key => {
                search_params = {...search_params, ...{[key]: record_values[key]}}
            })
            return search_params
        } else {
            return null
        }
    },
    after_table = (primary_key_columns, record_values, table) => {
        if (primary_key_columns && primary_key_columns.length > 0) {
            if (primary_key_columns.length === 1) {
                let resource_id = record_values[primary_key_columns[0]]
                if (resource_id) {
                    return `/${resource_id}`
                } else {
                    return ``
                }
            }
            if (primary_key_columns.length > 1) {
                let search_params = build_search_params(primary_key_columns, record_values)
                return `?search_params=${JSON.stringify(search_params)}`
            }
        } else {
            console.log('NO PRIMARY KEYS FOUND')
            return `?search_params=${JSON.stringify(record_values)}`
        }
    },
    build_url = (primary_key_columns, record_values, table) => {
        return `${api_prefix}${table}${after_table(primary_key_columns, record_values, table)}`
    },
    exact_primary_key_column = (primary_key_columns, record_values, form) => {
        if (primary_key_columns) {
            if (primary_key_columns.includes('payroll_no')) {
                return 'payroll_no'
            } else if (record_values && 'payroll_no' in record_values) {
                // Should be checking for if 'payroll_no' in form_element ids => caters for CREATES
                // Should be checking for if 'payroll_no' in record_values ids => caters for UPDATES
                return 'payroll_no'
            } else {
                // Should be checking for if 'payroll_no' in form_element ids => caters for CREATES
                let all_lines = []
                form.lines.forEach(line => {
                    line.forEach(element => {
                        element.id && all_lines.push(element_id(element.id))
                    })
                })
                if (all_lines.includes('payroll_no')) {
                    return 'payroll_no'
                }
            }
            // if (primary_key_columns.length > 1) {
            //     this.props.showAlert(false, 'More than one primary keys defined. Attempting the first primary key')
            // }
            return primary_key_columns && primary_key_columns[0]
        } else {
            console.log('NO PRIMARY KEYS FOR THIS ELEMENT')
            //    Need to put reference for purposes of creating new record
        }
    },
    isSubset = (superset, set) => {
        let includes = (key) => Object.keys(superset).includes(key)
        // set.keys.map(key => superset.keys().includes(key))
        return Object.keys(set).every(includes)
    },
    escape_characters = obj => {
        let new_obj = {}
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] == 'string' && obj[key].includes('&')) {
                new_obj[key] = obj[key].replace('&&', 'and').replace('&', 'and')
            } else {
                new_obj[key] = obj[key]
            }
        })
        return new_obj
    },
    dateToString = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).pad(2)}-${date.getDate().pad(2)}`
    },
    addMonthsToDate = (current_date, months) => {
        let curr = new Date(current_date),
            new_date = new Date(curr.setMonth(curr.getMonth() + parseInt(months)))
        return dateToString(new_date)
    },
    periodDifference = (first_date, second_date) => {
        if (first_date && second_date) {
            let first = new Date(first_date),
                second = new Date(second_date),
                period = new Date(second - first)
            return period.getUTCFullYear() - 1970
        }
    },
    build_default_fk = (element, values = {}, primary_key_columns) => {
        let search_params = {}
        if (element.fkey) {
            if (Array.isArray(element.fkey)) {
                if (element.pkey && Array.isArray(element.pkey)) {
                    element.pkey.forEach((key, index) => {
                        search_params = {...search_params, [element.fkey[index]]: values[key]}
                    })
                } else {
                    element.fkey.forEach(key => {
                        search_params = {...search_params, [key]: values[key]}
                    })
                }
            } else {
                if (element.pkey) {
                    search_params = {[element.fkey]: values[element.pkey]}
                } else {
                    search_params = {[element.fkey]: values[primary_key_columns[0]]}
                }
            }
        } else {
            primary_key_columns.forEach(pk => {
                if (values[pk]) {
                    search_params = {...search_params, [pk]: values[pk]}
                }
            })
        }
        return search_params
    }