import React, {useEffect} from 'react'
import Style from '../StyledBody'
import axios from 'axios'
import configs from "../../custom/configs";

let redirect_page = (location) => {
    return configs.default_page || '/dashboard'
}

const Dashboard = () => {
    useEffect(_ => {
        axios
            .get('/api/v1/auth')
            .then(response => {
                    let {authenticated} = response.data
                    // this.setState({authenticated})

                },
                error => {
                    console.log('There has been an error in confirming authentication', error)
                }
            )
    }, [])
    return (
        <div className="content-wrapper">
            <section className="content-header">
                <h1> Dashboard <small>Control panel</small></h1>
                <ol className="breadcrumb">
                    <li><a href="/#"><i className="fa fa-dashboard"/> Home</a></li>
                    <li className="active">Dashboard</li>
                </ol>
            </section>

            <section className="content">
                <div className="row">
                    <div className="col-lg-3 col-xs-6">
                        <div className="small-box bg-aqua">
                            <div className="inner">
                                <h3>150</h3>
                                <p>New Employees</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            <a href="/#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"/></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xs-6">
                        <div className="small-box bg-green">
                            <div className="inner">
                                <h3>53<sup className={{fontSize: "20px"}}>%</sup></h3>

                                <p>Tasks Done</p>
                            </div>
                            <div className="icon"><i className="ion ion-stats-bars"/>
                            </div>
                            <a href="/#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"/></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xs-6">
                        <div className="small-box bg-yellow">
                            <div className="inner">
                                <h3>44</h3>

                                <p>User Registrations</p>
                            </div>
                            <div className="icon"><i className="ion ion-person-add"/>
                            </div>
                            <a href="/#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"/></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xs-6">
                        <div className="small-box bg-red">
                            <div className="inner">
                                <h3>65</h3>

                                <p>Transferred</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-pie-graph"/>
                            </div>
                            <a href="/#" className="small-box-footer">More info <i className="fa fa-arrow-circle-right"/></a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <section className="col-lg-12 connectedSortable">
                        {/*<h2>Tables</h2>*/}
                        {/*<table className="dashboard table table-bordered table-condensed">*/}
                        {/*    <tbody>*/}
                        {/*    <tr>*/}
                        {/*        <th>*/}
                        {/*            <i className="fa fa-server"/>*/}

                        {/*            Roles*/}
                        {/*        </th>*/}
                        {/*        <td>*/}
                        {/*            <a href="/admin/role/" title="List Roles">*/}
                        {/*                <i className="glyphicon glyphicon-list"/> List Roles*/}
                        {/*            </a>*/}
                        {/*        </td>*/}
                        {/*        <td>*/}
                        {/*            <a href="/admin/role/new/?url=" title="Create New ">*/}
                        {/*                <i className="glyphicon glyphicon-plus"/> Create*/}
                        {/*            </a>*/}
                        {/*        </td>*/}
                        {/*    </tr>*/}
                        {/*    <tr>*/}
                        {/*        <th>*/}
                        {/*            <i className="fa fa-users"/>*/}

                        {/*            Users*/}
                        {/*        </th>*/}
                        {/*        <td>*/}
                        {/*            <a href="/admin/user/" title="List Users">*/}
                        {/*                <i className="glyphicon glyphicon-list"/> List Users*/}
                        {/*            </a>*/}
                        {/*        </td>*/}
                        {/*        <td>*/}
                        {/*            <a href="/admin/user/new/?url=" title="Create New ">*/}
                        {/*                <i className="glyphicon glyphicon-plus"/> Create*/}
                        {/*            </a>*/}
                        {/*        </td>*/}
                        {/*    </tr>*/}
                        {/*    </tbody>*/}
                        {/*</table>*/}
                    </section>
                </div>

            </section>

        </div>
    )
}


export default Style(Dashboard)