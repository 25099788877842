
export default {
    tab_name:'User Role(s)',
    type:'table',
    name: 'PROGRAM roles_users',
    post_url: '',
    table: 'RolesUsers',
    lines: [
        [{name: 'User', id: 'RolesUsers.user_id', type: 'select', source:'User.first_name', span:1, required: true}, {name:'User Name', type:'reference', reference:'user_id', id:'User.first_name'}],
        [{name: 'Role Id', id: 'RolesUsers.role_id', type: 'select', source:'Role.name', span:1, required: true}, {name:'Role Description', type:'reference', reference:'role_id', id:'Role.name'}]
    ]
}
