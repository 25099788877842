export default {
    name: 'Administration',
    roles: ['superuser', 'user'],
    children: [
        {
            name: 'User Management',
            target: 'setup',
            fa: 'fa-users',
            roles: ['superuser'],
            children: [
                {
                    name: 'Users',
                    target: 'PROGRAM user',
                    fa: 'fa-user'
                }, {
                    name: 'User Roles',
                    target: 'PROGRAM roles_users',
                    fa: 'fa-unlock'
                }, {
                    name: 'Roles',
                    target: 'PROGRAM roles',
                    fa: 'fa-lock'
                },
            ]
        }, {
            name: 'Development Menus',
            target: 'setup',
            fa: 'fa-cog',
            roles: ['superuser'],
            children: [
                {
                    name: 'System Problems',
                    target: 'Problems Form'
                },
                {
                    name: 'Development Notes',
                    target: 'Problems Form'
                }
            ]
        }, {
            name: 'System Statistics',
            target: 'statistics',
            fa: 'fa-cog',
        }
    ]
}