import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {signIn} from '../../store/actions/authactions'
// import {Link} from "react-router-dom";
import configs from "../../custom/configs";

import {withRouter} from 'react-router-dom'

class Form extends PureComponent {
    state = {
        username: null,
        password: null
    };

    handleOnChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.signIn(this.state)
    };


    render() {
        let {location, authReducer} = this.props,
            search_param = '?next=',
            message = (() => {
                if (authReducer.authError) {
                    return authReducer.authError.description
                }
                if (location.search && location.search.includes(search_param)) {
                    return 'Session expired. Please sign in again'
                }
                return null
            })()
        // console.log('Login History', {...this.props})
        return /*this.props.token ? <Redirect to={redirect_page()}/> :*/ (
                <div className="col-md-4 col-md-offset-4">
                    <div className="well">
                        <form onSubmit={this.handleSubmit} method="POST" name="login_user_form">
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <label htmlFor="email">{configs.login_type || 'Email Address'}</label>
                                </div>
                                <div className="col-md-8">
                                    <input className="form-control" id="username" name="username" required type="text" value={this.state.username || ''} onChange={this.handleOnChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-4">
                                    <label htmlFor="password">Password</label>
                                </div>
                                <div className="col-md-8">
                                    <input className="form-control" id="password" name="password" required type="password" value={this.state.password || ''} onChange={this.handleOnChange}/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6 col-md-offset-3">
                                    <input className="btn btn-primary form-control" id="submit" name="submit" type="submit" value="Login"/>
                                </div>
                            </div>
                            {message && <div className="alert alert-danger">
                                {message}
                            </div>}
                        </form>
                    </div>
                </div>
            )
    }
}

const mapStateToProps = (state, props) => {
    return {
        ...props,
        token: state.authReducer.token || null,
        authReducer: state.authReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials) => dispatch(signIn(credentials)),
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form))
