import React from 'react'
import {Redirect} from 'react-router-dom'

export const
    hide_reference_column = true,
    show_pk_in_list = true,
    show_pk_in_table = false,
    report_target = 'MyReport',
    show_link_in_table = false,
    star_nonexistent_option = false,
    buttons_top = true,

    login_title = 'FLEET',
    login_title_small = 'Management Information System',

    icon_large = 'FMIS',
    icon_small = 'FMIS',

    top_bar_color = 'purple',

    useModal = true,
    oneItemAsForm = false,
    itemsAsForm = 0 /*Can be 0 or 1 or 2 */,

    hideTableOnFormOpen = true,
    subTableBelow = false,
    showDevelopmentButton = true,
    default_icon_fa = 'certificate',
    redirect_to_login = false,
    login_type = 'Username',
    show_actions_column = false,
    default_page = '/page/recent_locations',
    show_label_pagination = true,
    Dashboard = props => {
        return <Redirect to={'/page/recent_locations'} />
    }

export default {showDevelopmentButton, report_target, default_icon_fa, redirect_to_login, login_type, show_actions_column, default_page, dashboard: Dashboard, show_label_pagination}