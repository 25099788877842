import foreign_keys from "../foreign_keys";

export default {
        name: 'PROGRAM service receipts',
        tab_name: 'Repair Receipts / Attachments',
        type: 'table',
        table: 'service_attachments',
        lines: [
            [
                {name:'Line No.', id:'service_attachments.line_no', type:'text', disabled: true, preprocessor:foreign_keys.increment_preprocessor},
                {name: 'Receipt / Attachment', id: 'service_attachments.attachment', type: 'file', col:4},
                {name: 'Service No.', id: 'service_attachments.service_id', type: 'select', source: 'vehicle_service.service_no', span: 1, col:4},
            ], [
                {name: 'Remarks', id: 'service_attachments.remarks', type: 'textarea', span: 3}
            ],
        ]
    }


