import driver_vehicle, {returned} from "./driver_vehicle";
import foreign_keys from "../foreign_keys";
import gprs_locations from "./gprs_locations";
import gprs_locations_recent from "./gprs_locations_recent";

let blood_options = [
        {value: 'A+'},
        {value: 'A-'},
        {value: 'B+'},
        {value: 'B-'},
        {value: 'AB+'},
        {value: 'AB-'},
        {value: 'O+'},
        {value: 'O-'},
    ],
    division = {name: 'Division', type: 'select', id: 'division', source: 'drivers.division', options: {value: 'division'}, creatable: true, span: 1},
    department = {name: 'Department', type: 'select', id: 'drivers.department', options: {value: 'department'}, creatable: true, span: 1}

export default {
    name: 'PROGRAM drivers',
    tab_name: 'Drivers',
    type: 'table',
    table: 'drivers',
    fa: 'user-circle',
    title: ['full_name'],
    lines: [
        [
            // {name: 'Employee Photo', id: 'drivers.photo', type: 'file', span: 1, col: 4},
            {name: 'Full Name', id: 'drivers.full_name', type: 'text', span: 1, col: 4, required: true},
            {name: 'PF. No.', id: 'drivers.employment_no', type: 'text', span: 1, col: 4},
            {name: 'ID No.', id: 'drivers.id_number', type: 'text', span: 1, col: 4},
        ], [
            {...division, showInModal: false},
            {...department, showInModal: false},
        ], [
            {name: 'RFID No.', id: 'drivers.rfid', type: 'text', span: 1, col: 4, required: true},
            {name: 'Level', id: 'drivers.level', type: 'select', span: 1, ...foreign_keys.year(1, 14), col: 4},
            {name: 'Gender', id: 'drivers.gender', type: 'select', options: [{value: 'Male'}, {value: 'Female'}], span: 1, col: 4},
            {name: 'License No.', id: 'drivers.license_no', type: 'text', span: 1, col: 4},
            {name: 'Permit No.', id: 'drivers.permit_no', type: 'text', span: 1, col: 4},
            {name: 'MV Class', id: 'drivers.mv_class', type: 'select', source: 'drivers.mv_class', options: {value: 'mv_class'}, creatable: true, span: 1, col: 4},
            {name: 'Issue Date', id: 'drivers.issue_date', type: 'date', span: 1, col: 4},
            {name: 'Blood Group', id: 'drivers.blood', type: 'select', options: blood_options, span: 1, col: 4},
            {name: 'C of C No.', id: 'drivers.c_of_c', type: 'text', span: 1, col: 4},
            {name: 'Phone No.', id: 'drivers.phone_no', type: 'text', span: 1, col: 4},
            {name: 'Email', id: 'drivers.email', type: 'email', span: 1, col: 4}
        ], [
            {...division, showInTable: false},
            {...department, showInTable: false}
        ], [
            {name: 'Remarks', id: 'drivers.remarks', type: 'textarea', span: 3}
        ],
        [{...gprs_locations_recent, label: 'Current Location', fkey: 'rfid', pkey: 'rfid', fa: 'map-pin'}],
        [{...gprs_locations, fkey: 'rfid', pkey: 'rfid', label: 'Previous Locations'}],
        // [driver_vehicle],
        // [returned]

    ]
}