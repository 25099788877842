
import OSMMap from './maps/MapsOSM'

const preprocessor = function(state, props){
    if (props && props.form_values && props.form_values.latitude && props.form_values.longitude && !isNaN(props.form_values.latitude ) && !isNaN(props.form_values.longitude)) {
        return {
            lat: props.form_values.latitude,
            lng: props.form_values.longitude
        }
    }
}

export default {
    name: 'PROGRAM tracker location',
    tab_name: 'Tracker Locations',
    type: 'table',
    table: 'tracker_location',
    fa: 'map-marker',
    lines: [
        [
            {name: 'Location ID', id: 'tracker_location.location_id', type: 'text', disabled: true, showInTable:true},
            {name: 'Tracker Serial', id: 'tracker_location.tracker_serial', type: 'select', source: 'trackers.tracker_serial', span: 1, required: true},
        ], [
            {name: 'GOK Plate Number', id: 'trackers.chassis_no', type: 'reference', reference: 'tracker_serial', disabled: false, span: 1},
            {name: 'GOK Plate Number', id: 'vehicles.civilian_plate_no', type: 'reference', reference: 'chassis_no', span: 1},
        ], [
            {name: 'Latitude', id: 'tracker_location.latitude', type: 'text', span: 1, required: true},
            {name: 'Longitude', id: 'tracker_location.longitude', type: 'text', span: 1, required: true},
        ], [
            {name: 'Date Recorded', id: 'tracker_location.created_at', type: 'text', disabled:true, span: 1.5, showInTable: true},
        ], [
            {name: 'Map', id: 'tracker_map', type: 'custom', span: 3, component: OSMMap, showInTable: false},
        ]

    ]
}


/*
* Todo
*   trigger to send notification if vehicles insurance number is due for renewal*/