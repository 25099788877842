import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import Style from "../StyledBody";
import axios from 'axios';
import {withRouter} from 'react-router-dom'
import Select from 'react-select'
import './floatlabel2.css'
import './forms.css'


const FloatingLabels = () => {
    return (
        <div className="row">
            <div className="col-md-6">
                <div id="form">
                    <form id="register_form" action="null">
                        <div className="input-container" id="first-name">
                            <input type="text" name="first-name" required/>
                            <label htmlFor="first-name">First name</label>
                        </div>
                        <div className="input-container" id="last-name">
                            <input type="text" name="last-name" required/>
                            <label htmlFor="last-name">Last name</label>
                        </div>
                        <div className="input-container" id="address">
                            <input type="text" name="address" required/>
                            <label htmlFor="address">Address</label>
                        </div>
                        <div className="input-container" id="postal">
                            <input type="text" name="postal" required maxLength="6"/>
                            <label htmlFor="postal">Postal Code</label>
                        </div>
                        <div className="input-container" id="city">
                            <input type="text" name="city" required/>
                            <label htmlFor="city">City</label>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const FloatingLabels2 = () => {
    return <>
        <h1>ANOTHER EXAMPLE</h1>
        <div className="form-group input-group">
            <label className="has-float-label">
                <input className="form-control" type="text" placeholder="Name"/>
                <span>Name</span>
            </label>
        </div>
        <div className="form-group input-group">
            <label className="has-float-label">
                <input className="form-control" type="text" placeholder="Surname"/>
                <span>Surname</span>
            </label>
        </div>
    </>
}

class ReactSelect extends PureComponent {
    state = {
        selectedOption: null
    }

    handleChange = selected => {
        this.setState({
            selectedOption: selected
        })
    };

    // list of values in the select box
    options = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'},
    ];

//custom css for dropdown form field
    customStyles = {
        control: provided => {
            return {
                ...provided,
                borderRadius: 0 // adding borderRadius style to remowe border radius
            };
        },
        dropdownIndicator: provided => ({
            ...provided,
            display: "none", // remowing dropdown indicator

        })
    };

    render() {

        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mx-auto">
                        <Select
                            value={this.state.selectedOption}
                            onChange={this.handleChange}
                            options={this.options}
                            searchable
                            styles={this.customStyles}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

class Index extends PureComponent {

    state = {
        selectedOption: null,
    };

    handleChange = selectedOption => {
        this.setState(
            {selectedOption},
            () => console.log(`Option selected:`, this.state.selectedOption)
        );
    };


    url = 'http://192.168.60.20/reports/downloadable';
    doReport = () => {
        axios.get(this.url)
            .then(respond => {
                console.log(respond)
            }).catch((error) => {
            console.log(error)
        })
    }

    render() {
        return (
            <div className="content-wrapper">
                <section className="content-header">
                    <h4>Some heading</h4>
                    <ol className="breadcrumb">
                        <li><a href="/#"><i className="fa fa-dashboard"/> Home</a></li>
                        <li className="active">Dashboard</li>
                    </ol>
                </section>
                <section className="content">
                    <div className="row">
                        <div className="col-md-6">
                            <button onClick={this.doReport}>Get Report</button>
                            <form action={this.url}>
                                <button>Get Report Downloaded</button>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            Show pdf
                            {/*<object width='100%' height='400px' data={this.url} type='application/pdf'/>*/}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <FloatingLabels key={1}/>
                            <FloatingLabels2 key={2}/>
                            <ReactSelect key={3}/>
                        </div>
                    </div>
                </section>
            </div>

        )

    }
}


const mapStateToProps = (state) => {
    return {
        ...state.pageReducer
    }
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default withRouter(Style(connect(mapStateToProps, mapDispatchToProps)(Index)))
